import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    styled,
} from '@mui/material';
import {Feature, MultiPolygon, Polygon} from 'geojson';
import {useDispatch, useSelector} from 'react-redux';
import React from 'react';
import proj4 from 'proj4';
import {multiPolygon} from '@turf/helpers';
import centerOfMass from '@turf/center-of-mass';
import {MapRoutePins1Icon} from '@norkart/toi-icons';
import {subSearch} from '../../nkmNorkartSearch/utils/subSearchFunctions';
import {getResult} from '../../nkmNorkartSearch/utils/resultFunctions';
import NkmNorkartSearch from '../../nkmNorkartSearch';
import {Arealplan} from '../../hooks/arealplaner/types';
import useMeta from '../../hooks/meta/useMeta';
import {getPropertyGeometry} from '../../services/matrikkelkart';
import {getMapState} from '../../store';
import arealplanerConfig from '../../config';
import {NkmSearchResponse} from '../search/Search/SearchResponse';
import LoadingSpinner from '../../components/LoadingSpinner';
import Error from '../../components/FormError/Error';
import {
    getProj4Defs,
    toLatLng,
} from '../behandling/BehandlingFormDialog/helpers';
import * as actions from '../../store/map/actions';
import PositionMenu from './Components/Menus/PositionMenu';
import PlanensKartlag from './Components/Menus/Kartlag/PlanensKartlag';
import MenuButton from './Components/Menus/MenuButton';
import BaseMap from './BaseMap';
import {Coords} from './types';
import {createFitBoundsForFeature, isWithinLayer} from './helpers/layerHelpers';

const DialogWrapper = styled(Dialog)({
    '& .MuiDialog-paper': {
        maxWidth: '80vw',
        width: '80vw',
        height: '90vh',
    },
});

type Props = {
    setDisplaySelectMapPosition: (value: boolean) => void;
    onPositionSelected: (coords: {x: number; y: number} | undefined) => void;
    initCoords?: {x: number; y: number};
    vertikalnivaId?: number;
    title?: string;
    plan?: Arealplan;
};

const SelectMapPosition = (props: Props) => {
    const dispatch = useDispatch();
    const mapState = useSelector(getMapState);
    const meta = useMeta();
    const [searchOpen, setSearchOpen] = React.useState(true);
    const [selectedPosition, setSelectedPosition] = React.useState<
        Coords | undefined
    >(undefined);
    const [searchResultError, setSearchResultError] = React.useState<
        {message: string} | undefined
    >();
    const [propertyGeom, setPropertyGeom] =
        React.useState<Feature<MultiPolygon>>();

    const [positionError, setPositionError] = React.useState(false);

    React.useEffect(() => {
        if (props.initCoords) {
            setSelectedPosition(
                toLatLng(props.initCoords, meta.referansesystemKode)
            );
        } else {
            setSelectedPosition(getCenterOfPlan());
        }
    }, []);

    React.useEffect(() => {
        if (props.vertikalnivaId && mapState.wmsLayerState.availableWmsLayers) {
            const correspondingWmsLayerName =
                meta.lagKategorier.vertikalMapping[props.vertikalnivaId];
            const layer = mapState.wmsLayerState.availableWmsLayers.find(
                (b) => b.ruleset == correspondingWmsLayerName
            );

            if (layer) {
                dispatch(actions.addWmsLayer(layer));
            }
        }
    }, [props.vertikalnivaId]);

    const getMultiPolygon = (geoms: Polygon[]): Feature<MultiPolygon> => {
        return multiPolygon(
            geoms.map((geom) => geom.coordinates)
        ) as Feature<MultiPolygon>;
    };

    const getCenterOfPlan = (): Coords | undefined => {
        if (mapState.borderLayers?.combined.source) {
            const res = centerOfMass(
                (mapState.borderLayers.combined.source as any).data
            );
            if (res && res.geometry) {
                return {
                    lat: res.geometry.coordinates[1],
                    lng: res.geometry.coordinates[0],
                };
            }
        }
    };

    const onDragEnd = (coord: Coords) => {
        if (
            positionError &&
            isWithinLayer(coord, mapState.borderLayers?.combined)
        )
            setPositionError(false);
        setSelectedPosition(coord);
    };

    function round(value: number, precision: number) {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    const transformToSaveCoordSys = (pos: Coords | undefined) => {
        proj4.defs(getProj4Defs());
        if (pos && meta.referansesystemKode) {
            const projectedCoords: {x: number; y: number} = proj4(
                'EPSG:4326',
                meta.referansesystemKode,
                {
                    x: pos.lng,
                    y: pos.lat,
                }
            );
            const roundedProjectedCoords: {x: number; y: number} = {
                x: round(projectedCoords.y, 1), //proj4 has x and y opposite
                y: round(projectedCoords.x, 1),
            };
            return roundedProjectedCoords;
        }
    };

    const handleSelectPosition = () => {
        if (
            selectedPosition &&
            !isWithinLayer(selectedPosition, mapState.borderLayers?.combined)
        ) {
            setPositionError(true);
        } else {
            setPositionError(false);
            if (transformedPosition) {
                props.onPositionSelected(transformedPosition);
                props.setDisplaySelectMapPosition(false);
            } else {
                console.error('no coords to select');
            }
        }
    };

    const onCancel = () => {
        props.setDisplaySelectMapPosition(false);
    };
    const onDelete = () => {
        props.onPositionSelected(undefined);
        props.setDisplaySelectMapPosition(false);
    };

    const transformedPosition: {x: number; y: number} | undefined =
        transformToSaveCoordSys(selectedPosition);

    const onPropertySearch = (event) => {
        const res: NkmSearchResponse = getResult(event);

        if (res) {
            const latLangCoords = {
                lng: res.Posisjon.Point.X as number,
                lat: res.Posisjon.Point.Y as number,
            };

            if (isWithinLayer(latLangCoords, mapState.borderLayers?.combined)) {
                setSelectedPosition(latLangCoords);
                setSearchResultError(undefined);

                //Zoom til eiendom hvis det er enten en matrikkelenhet eller en gateadresse
                panToSearchResult(res.properties.Type, res.properties.Source);
            } else {
                setSearchResultError({message: 'Posisjon utenfor planområde'});
            }
        }
    };

    const panToSearchResult = (type: string, result: any) => {
        if (type === 'gateadresse') {
            getPropertyGeometry(result.AdresseMatrikkelenhetId).then((g) => {
                if (g && g !== 'error') {
                    const multiGeom = getMultiPolygon(
                        g.Teiger.map(
                            (teig) => JSON.parse(teig.Geometri) as Polygon
                        )
                    );
                    const bounds = createFitBoundsForFeature(multiGeom);
                    dispatch(actions.addFitToBounds(bounds));
                    setPropertyGeom(multiGeom);
                }
            });
        } else if (type === 'matrikkelenhet') {
            getPropertyGeometry(result.MatrikkelNummer).then((g) => {
                if (g && g !== 'error') {
                    const multiGeom = getMultiPolygon(
                        g.Teiger.map(
                            (teig) => JSON.parse(teig.Geometri) as Polygon
                        )
                    );
                    const bounds = createFitBoundsForFeature(multiGeom);
                    dispatch(actions.addFitToBounds(bounds));
                    setPropertyGeom(multiGeom);
                }
            });
        }
    };

    const onClearResult = () => {
        setSearchResultError(undefined);
        //setFitBounds(planAreaFitBounds)
    };

    return (
        <DialogWrapper fullWidth={true} open={true}>
            <DialogTitle style={{textAlign: 'center'}}>
                <Typography style={{fontSize: 28}}>
                    {props.title || 'Velg posisjon'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {mapState.wmsLayerState.loading ||
                !mapState.borderLayers?.combined ? (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <>
                        <Box
                            sx={{
                                maxHeight: '95vh',
                                minWidth: '252px',
                                maxWidth: '300px',
                                position: 'absolute',
                                zIndex: 1,
                                overflow: 'hidden',
                                display: 'flex',
                                flexDirection: 'column' as any,
                                height: 'auto',
                                right: 30,
                                top: 95,
                                '& > .section': {
                                    marginTop: '10px',
                                },
                            }}
                        >
                            <PlanensKartlag defaultClosed={false} />
                            <PositionMenu
                                selectedPosition={transformedPosition}
                            />
                            <div
                                style={{
                                    minHeight: 400,
                                    marginTop: 20,
                                }}
                            >
                                <MenuButton
                                    name='Søk'
                                    Icon={<MapRoutePins1Icon />}
                                    onClick={() => setSearchOpen(!searchOpen)}
                                    isOpen={searchOpen}
                                />
                                {searchOpen && (
                                    <Grid
                                        container={true}
                                        direction='row'
                                        style={{
                                            backgroundColor: '#eaeaea',
                                            padding: 10,
                                        }}
                                    >
                                        <Grid
                                            style={{overflowY: 'visible'}}
                                            item={true}
                                            xs={12}
                                            sx={{
                                                div: {
                                                    '& .nk-search, .result-list':
                                                        {
                                                            minHeight: '200px',
                                                            maxHeight: '300px',
                                                        },
                                                },
                                            }}
                                        >
                                            <NkmNorkartSearch
                                                autofocus={false}
                                                placeholder={
                                                    'Søk adresse, gnr/bnr..'
                                                }
                                                searchIconColor={'black'}
                                                apiKey={
                                                    arealplanerConfig
                                                        .planregister.token
                                                }
                                                targets={[
                                                    'matrikkelenhet',
                                                    'gate',
                                                    'gateadresse',
                                                ]}
                                                subSearch={subSearch}
                                                numResults={5}
                                                shouldFormatResult={true}
                                                limits={[meta.komnr]}
                                                hitSelected={onPropertySearch}
                                                whenClearingResults={
                                                    onClearResult
                                                }
                                            />
                                            <Error
                                                error={
                                                    searchResultError?.message
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                top: 0,
                                left: 0,
                                '& .nkm-mapbox-map, .mapboxgl-map': {
                                    width: '100%',
                                    height: '100%',
                                },
                            }}
                        >
                            <BaseMap
                                plan={props.plan}
                                onMapClick={(e) => {
                                    setSelectedPosition({
                                        lat: e.lngLat.lat,
                                        lng: e.lngLat.lng,
                                    });
                                }}
                                mapboxMarker={
                                    selectedPosition && {
                                        coords: selectedPosition,
                                        draggable: true,
                                        onDragEnd: onDragEnd,
                                    }
                                }
                            />
                        </Box>
                    </>
                )}
            </DialogContent>
            {positionError && (
                <Typography
                    style={{color: 'red', margin: 'auto', textAlign: 'center'}}
                >
                    Posisjonen må være innenfor planområdet
                </Typography>
            )}
            <DialogActions
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 20,
                }}
            >
                <Button
                    onClick={onCancel}
                    variant='outlined'
                    color='primary'
                    style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '200px',
                    }}
                >
                    Avbryt
                </Button>
                <Button
                    onClick={onDelete}
                    variant='outlined'
                    color='primary'
                    style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '200px',
                    }}
                >
                    Slett posisjon
                </Button>
                <Button
                    onClick={handleSelectPosition}
                    variant='contained'
                    color='secondary'
                    style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '200px',
                    }}
                >
                    Velg posisjon
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

export default SelectMapPosition;
