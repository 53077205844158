import {useState} from 'react';
import {
    ToiBox,
    ToiButton,
    ToiCard,
    ToiDialog,
    ToiDialogActions,
    ToiDialogTitle,
    ToiDivider,
    ToiLink,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import {
    AppExternalIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from '@norkart/toi-icons';
import {Chunk, ReferenceObject} from '../types';

type Props = {
    title: string;
    contentText: string;
    reference: ReferenceObject;
};

export const ReferenceChatItem = ({title, contentText, reference}: Props) => {
    const [openReferences, setOpenReferences] = useState(false);
    const [currentSelectedChunk, setCurrentSelectedChunk] =
        useState<Chunk | null>(null);

    const isOpenChunk = Boolean(currentSelectedChunk);

    const closeReference = () => {
        setCurrentSelectedChunk(null);
    };

    const handleOpenReferences = () => {
        setOpenReferences(!openReferences);
    };

    const getChunksInUse = () => {
        return reference.chunks.filter((chunk) => chunk.is_used);
    };

    return (
        <ToiStack gap={1}>
            <ToiTypography variant='body2' color='black' bold>
                {title} sier dette:
            </ToiTypography>
            <ToiTypography variant='body3' color='black'>
                {contentText}
            </ToiTypography>
            {!openReferences ? (
                <ToiBox display={'flex'} flexWrap={'wrap'} mb={2}>
                    <ToiButton
                        variant='outlined'
                        endIcon={<ChevronDownIcon />}
                        onClick={handleOpenReferences}
                    >
                        Referanser
                    </ToiButton>
                </ToiBox>
            ) : (
                <>
                    <ToiBox display={'flex'} flexWrap={'wrap'} mb={2}>
                        <ToiButton
                            variant='outlined'
                            endIcon={<ChevronUpIcon />}
                            onClick={handleOpenReferences}
                        >
                            Referanser
                        </ToiButton>
                    </ToiBox>
                    <ToiCard
                        sx={{
                            height: 'min-content',
                            mb: 3,
                        }}
                    >
                        <ToiTypography sx={{m: 1}} variant='subtitle2'>
                            Referanser
                        </ToiTypography>
                        <ToiDivider sx={{m: 1}} />
                        {reference.link ? (
                            <ToiTypography sx={{m: 1}} variant='body2'>
                                {title}:{' '}
                                <ToiLink
                                    inline
                                    href={reference.link.url}
                                    target='_blank'
                                >
                                    {reference.link.title}
                                    <AppExternalIcon size='small' />
                                </ToiLink>
                            </ToiTypography>
                        ) : (
                            <ToiTypography sx={{mt: 1, ml: 1}} variant='body2'>
                                {title}:
                            </ToiTypography>
                        )}
                        {getChunksInUse().length > 0 && (
                            <ToiStack gap={1} ml={2} mb={1}>
                                {getChunksInUse().map((chunk, i) => {
                                    return (
                                        <ToiLink
                                            variant='body2'
                                            onClick={(e) =>
                                                setCurrentSelectedChunk(chunk)
                                            }
                                        >
                                            {`[${
                                                chunk.id
                                            }] ${chunk.content.substring(
                                                0,
                                                20
                                            )}`}
                                        </ToiLink>
                                    );
                                })}
                            </ToiStack>
                        )}
                    </ToiCard>
                    <ToiDialog
                        open={isOpenChunk}
                        onClose={closeReference}
                        maxWidth={'lg'}
                        scroll='paper'
                    >
                        <ToiDialogTitle>Referanse</ToiDialogTitle>
                        <ToiBox
                            sx={{
                                border: '2px solid grey',
                                backgroundColor: 'gainsboro',
                                borderRadius: '5px',
                                margin: '1vw',
                            }}
                        >
                            <ToiTypography sx={{m: 2, maxWidth: '80vw'}}>
                                {currentSelectedChunk?.content}
                            </ToiTypography>
                        </ToiBox>
                        <ToiDialogActions>
                            <ToiButton onClick={closeReference} size='large'>
                                Lukk
                            </ToiButton>
                        </ToiDialogActions>
                    </ToiDialog>
                </>
            )}
        </ToiStack>
    );
};
