import {Polygon, Feature, MultiPolygon} from 'geojson';
import {MapboxLayer} from '@norkart/nkm-mapbox-map';
import {toiColors} from '@norkart/toi-colors';
import {WmsFeature} from '../../../services/apiTjenestekatalogen';
import {MapState, PlanAreaLayers} from '../../../store/map/types';
import {AvailableWmsLayer} from '../types';
import {getMapboxLayer, formatToMapboxLayer} from './getMapboxLayer';
import {mapConstants, vertikalMapping} from './constants';

export const getLayersToDraw = (
    mapState: MapState,
    searchedPropertyGeom: undefined | Feature<MultiPolygon> | Feature<Polygon>,
    displaySearchedPropertyGeom: boolean,
    displaySurroundingPlanWms: boolean,
    visibleGfiFeature?: WmsFeature
): MapboxLayer[] => {
    const layersToDraw: MapboxLayer[] = [];

    if (mapState.borderLayers && mapState.wmsLayerState.availableWmsLayers) {
        //Draw the appropriate borderlayer ( used for outline of planarea ) depending on available and visible vertikalnivaa
        layersToDraw.push(
            ...getPlanAreaLayersToDraw(
                mapState.borderLayers,
                mapState.wmsLayerState.availableWmsLayers
            )
        );
    }
    if (mapState.differenceLayer && mapState.wmsLayerState.availableWmsLayers) {
        //Draw the appropriate differenceLayer ( used for clipping to plan area ) depending on available and visible vertikalnivaa
        layersToDraw.push(
            ...getPlanAreaLayersToDraw(
                mapState.differenceLayer,
                mapState.wmsLayerState.availableWmsLayers,
                displaySurroundingPlanWms
            )
        );
    }

    if (visibleGfiFeature) {
        layersToDraw.push(
            getMapboxLayer(
                visibleGfiFeature.Geometry,
                visibleGfiFeature.Interiors,
                visibleGfiFeature.id
            )
        );
    }

    if (searchedPropertyGeom && displaySearchedPropertyGeom) {
        //legg til lag for eiendomsgrense for søkt eiendom
        const propertyFillLayer = formatToMapboxLayer(
            searchedPropertyGeom.geometry,
            'property_geom_fill',
            {
                'fill-color': toiColors.blue[80],
                'fill-opacity': 0.2,
            },
            mapConstants.defaultBeforeLayerId,
            'fill'
        );
        layersToDraw.push(propertyFillLayer);
        const propertyLineLayer = formatToMapboxLayer(
            searchedPropertyGeom.geometry,
            'property_geom_line',
            {
                'line-color': toiColors.blue[80],
                'line-width': 3,
            },
            mapConstants.defaultBeforeLayerId,
            'line'
        );
        layersToDraw.push(propertyLineLayer);
    }
    return layersToDraw;
};

function getPlanAreaLayersToDraw(
    planAreaLayers: PlanAreaLayers,
    availableWmsLayers: AvailableWmsLayer[],
    displaySurroundingPlanWms = false
) {
    const layersToDraw: MapboxLayer[] = [];
    let vertnivOn = false;

    const underGrunnen = availableWmsLayers.some(
        (layer) =>
            layer.isVisible &&
            vertikalMapping[1].some((vertikalRule) =>
                layer.ruleset.some((layerRule) => vertikalRule === layerRule)
            )
    );
    const paaGrunnen = availableWmsLayers.some(
        (layer) =>
            layer.isVisible &&
            vertikalMapping[2].some((vertikalRule) =>
                layer.ruleset.some((layerRule) => vertikalRule === layerRule)
            )
    );
    const overGrunnen = availableWmsLayers.some(
        (layer) =>
            layer.isVisible &&
            vertikalMapping[3].some((vertikalRule) =>
                layer.ruleset.some((layerRule) => vertikalRule === layerRule)
            )
    );

    if (planAreaLayers.combined && underGrunnen && paaGrunnen && overGrunnen) {
        vertnivOn = true;
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.combined,
                displaySurroundingPlanWms,
                'seethroughCombined'
            )
        );
    } else if (planAreaLayers.vertniv1and2 && underGrunnen && paaGrunnen) {
        vertnivOn = true;
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.vertniv1and2,
                displaySurroundingPlanWms,
                'seethrough12'
            )
        );
    } else if (planAreaLayers.vertniv1and3 && underGrunnen && overGrunnen) {
        vertnivOn = true;
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.vertniv1and3,
                displaySurroundingPlanWms,
                'seethrough13'
            )
        );
    } else if (planAreaLayers.vertniv2and3 && paaGrunnen && overGrunnen) {
        vertnivOn = true;
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.vertniv2and3,
                displaySurroundingPlanWms,
                'seethrough23'
            )
        );
    } else if (planAreaLayers.vertniv1 && underGrunnen) {
        vertnivOn = true;
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.vertniv1,
                displaySurroundingPlanWms,
                'seethrough1'
            )
        );
    } else if (planAreaLayers.vertniv2 && paaGrunnen) {
        vertnivOn = true;
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.vertniv2,
                displaySurroundingPlanWms,
                'seethrough2'
            )
        );
    } else if (planAreaLayers.vertniv3 && overGrunnen) {
        vertnivOn = true;
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.vertniv3,
                displaySurroundingPlanWms,
                'seethrough3'
            )
        );
    }
    if (planAreaLayers.combined) {
        layersToDraw.push(
            getSeethroughDiffLayer(
                planAreaLayers.combined,
                displaySurroundingPlanWms,
                'seethroughcombined'
            )
        );
    }

    return layersToDraw;
}

function getSeethroughDiffLayer(
    diffLayer: MapboxLayer,
    displaySurroundingPlanWms: boolean,
    id: string
) {
    return displaySurroundingPlanWms
        ? ({
              ...diffLayer,
              id: id,
              paint: {...diffLayer.paint, 'fill-opacity': 0.5},
          } as MapboxLayer)
        : diffLayer;
}
