import CircleIcon from '@mui/icons-material/Circle';
import {Box, Card, CardContent, CardHeader, Chip, Grid, Icon, IconButton, Typography} from '@mui/material';
import {padStart} from 'lodash-es';
import {DateTime} from 'luxon';
import DOMPurify from 'dompurify';
import {AppEditIcon, AppDeleteRecycleIcon} from '@norkart/toi-icons';
import {useIsTabletOrMobile} from '@norkart/design-responsive';
import {useTags} from '../Hooks';
import postType from '../Types/PostType';

type props = {
  post: postType;
  onEdit: (post: postType) => void;
  onDelete: (post: postType) => void;
  isAuthenticated: boolean;
};

function Post({post, onEdit, onDelete, isAuthenticated}: props) {
  const tags = useTags();
  const isTabletOrMobile = useIsTabletOrMobile();

  let date = DateTime.fromISO(post.date.toString(), {zone: 'UTC'});
  date = date.setZone('local');

  if (isTabletOrMobile) {
    return (
      <>
        <Box
          sx={{
            background: 'linear-gradient(#000, #000) no-repeat center/2px 100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            justifyItems: 'center',
            alignItems: 'end',
          }}
        >
          <Typography sx={{marginX: 4, justifySelf: 'start'}} variant='h6'>
            {date.day + '.' + date.month + '.' + date.year}
          </Typography>
          <Icon>
            <CircleIcon />
          </Icon>
          <Typography sx={{marginX: 4, justifySelf: 'end'}} variant='body1'>
            {padStart(date.hour.toString(), 2, '0') + ':' + padStart(date.minute.toString(), 2, '0')}
          </Typography>
        </Box>
        <Box
          sx={{
            background: 'linear-gradient(#000, #000) no-repeat center/2px 100%',
          }}
        >
          <Card variant='outlined' sx={{marginY: 2, marginX: 4}}>
            <CardHeader
              title={post.title}
              action={
                isAuthenticated ? (
                  <>
                    <IconButton onClick={() => onEdit(post)}>
                      <AppEditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDelete(post)}>
                      <AppDeleteRecycleIcon />
                    </IconButton>
                  </>
                ) : null
              }
            />
            <CardContent>
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.content),
                }}
              ></Typography>
              {tags.data.map((tag) =>
                post.tagIds.includes(tag.id) ? (
                  <Chip label={tag.name} key={tag.id} size='small' variant='outlined' />
                ) : null
              )}
            </CardContent>
          </Card>
        </Box>
      </>
    );
  } else {
    return (
      <Grid container>
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'right',
            textAlign: 'right',
            justifyContent: 'center',
            paddingRight: '10px',
          }}
        >
          <Typography variant='body1'>
            {padStart(date.hour.toString(), 2, '0') + ':' + padStart(date.minute.toString(), 2, '0')}
          </Typography>
          <Typography variant='h6'>{date.day + '.' + date.month + '.' + date.year}</Typography>
        </Grid>
        <Grid
          item
          xs={0.25}
          style={{
            background: 'linear-gradient(#000, #000) no-repeat center/2px 100%',
            display: 'flex',
            placeItems: 'center',
          }}
        >
          <Icon sx={{flexGrow: 1}}>
            <CircleIcon />
          </Icon>
        </Grid>
        <Grid item xs={10}>
          <Card variant='outlined' sx={{marginY: 2, marginX: 4}}>
            <CardHeader
              title={post.title}
              action={
                isAuthenticated ? (
                  <>
                    <IconButton onClick={() => onEdit(post)}>
                      <AppEditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDelete(post)}>
                      <AppDeleteRecycleIcon />
                    </IconButton>
                  </>
                ) : null
              }
            />
            <CardContent>
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.content),
                }}
              ></Typography>
              {tags.data.map((tag) =>
                post.tagIds.includes(tag.id) ? (
                  <Chip label={tag.name} key={tag.id} size='small' variant='outlined' />
                ) : null
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Post;
