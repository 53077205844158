import {
  styled,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  TypographyTypeMap as MuiTypographyTypeMap,
} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React from 'react';
import {useIsMobile} from '@norkart/design-responsive';
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {ToiThemeOptions} from '@norkart/toi-theme';
import {toiTypographyStyle} from './toiTypographyStyle';
import {validateTypographyAttributes} from './TypographyVariantValidator';

export interface ITypographyVariantOverrides {
  body3: true;
  label: true;
}

declare module '@mui/material/Typography' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyPropsVariantOverrides extends ITypographyVariantOverrides {}
}

export type ToiTypographyVariant =
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'button'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subtitle1'
  | 'subtitle2'
  | 'label'
  | 'inherit';

export type TypographyAttributes = {
  uppercase?: boolean;
  bold?: boolean;
  italic?: boolean;
  light?: boolean;
  semibold?: boolean;
};

export type OwnProps = TypographyAttributes & {variant?: ToiTypographyVariant};

export type ToiTypographyProps<C extends React.ElementType> = Omit<
  MuiTypographyProps<C, {component?: C; ref?: React.Ref<C>}>,
  keyof OwnProps
> &
  OwnProps;

type ToiTypographyType = OverridableComponent<{
  props: Omit<MuiTypographyTypeMap<OwnProps>['props'], keyof OwnProps> & OwnProps;
  defaultComponent: MuiTypographyTypeMap<OwnProps>['defaultComponent'];
}>;

export const getDefaultTypographyVariant = (theme: ToiThemeOptions) =>
  theme.components?.ToiTypography?.defaultProps?.variant as ToiTypographyVariant;

const getCustomVariantComponent = (variant: ToiTypographyVariant) => {
  if (variant === 'body3') {
    return 'p';
  } else if (variant === 'label') {
    return 'label';
  } else {
    return undefined;
  }
};

const StyledTypography = styled(MuiTypography, {
  shouldForwardProp: (prop) => !['uppercase', 'bold', 'italic', 'light', 'semibold'].includes(prop.toString()),
})<TypographyAttributes>(({theme, variant, uppercase, bold, italic, light, semibold}) => {
  const defaultVariant = getDefaultTypographyVariant(theme);
  const isMobile = useIsMobile();

  validateTypographyAttributes({
    variant: (variant || defaultVariant) as ToiTypographyVariant,
    uppercase: uppercase,
    bold: bold,
    italic: italic,
    light: light,
    semibold: semibold,
  });

  return toiTypographyStyle(
    theme,
    (variant || defaultVariant) as ToiTypographyVariant,
    {
      uppercase: uppercase,
      bold: bold,
      italic: italic,
      light: light,
      semibold: semibold,
    },
    isMobile
  );
});

export const ToiTypography = React.forwardRef(function ToiTypographyInner<D extends React.ElementType>(
  props: ToiTypographyProps<D>,
  ref: React.ComponentPropsWithRef<D>['ref']
) {
  const {children, ...restProps} = props;

  return (
    <StyledTypography
      component={
        props['component'] ? props['component'] : getCustomVariantComponent(props['variant'] as ToiTypographyVariant)
      }
      ref={ref}
      {...restProps}
    >
      {children}
    </StyledTypography>
  );
}) as ToiTypographyType;

export default ToiTypography;
