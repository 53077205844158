import {ComponentPropsWithRef, ElementType, forwardRef} from 'react';
import {styled} from '@mui/material';
import ToiTypography, {ToiTypographyProps} from '../../ToiTypography/ToiTypography';
import {toiDialogTitleStyle} from './toiDialogTitleStyle';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledDialogTitle = styled('div')(({theme}): any => {
  return toiDialogTitleStyle(theme);
});

export const ToiDialogTitle = forwardRef(
  <D extends ElementType>(props: ToiTypographyProps<D>, ref: ComponentPropsWithRef<D>['ref']) => {
    const {variant = 'h3', component = 'h1', ...restProps} = props;
    return (
      <StyledDialogTitle ref={ref}>
        <ToiTypography variant={variant} component={component} {...restProps}></ToiTypography>
      </StyledDialogTitle>
    );
  }
);
