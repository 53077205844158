import {
    ToiBox,
    ToiButton,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import {ConversationType} from '../types';

type Props = {
    setExampleQuestion: (q: string) => void;
    selectedConversation: ConversationType;
    chatInputRef?: React.RefObject<HTMLDivElement>;
};

export const ExampleQuestions = ({
    setExampleQuestion,
    selectedConversation,
    chatInputRef,
}: Props) => {
    const onClickExampleQuestion = (question: string) => {
        setExampleQuestion(question);
        focusChatInput();
    };

    const focusChatInput = () => {
        if (chatInputRef?.current) {
            chatInputRef.current.focus();
        }
    };

    return (
        <ToiStack gap={2}>
            <ToiTypography variant='h4'>Eksempler på spørsmål</ToiTypography>
            <ToiBox display={'flex'} gap={2} flexWrap={'wrap'}>
                {selectedConversation.sampleQuestions.map((q, index) => (
                    <ToiButton
                        key={index}
                        variant='outlined'
                        color='secondary'
                        size='medium'
                        sx={{boxShadow: 'none'}}
                        onClick={() => onClickExampleQuestion(q)}
                    >
                        {q}
                    </ToiButton>
                ))}
            </ToiBox>
        </ToiStack>
    );
};
