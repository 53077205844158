import React from 'react';
import {MapRoutePins1Icon} from '@norkart/toi-icons';
import {getProj4Def} from '../../../behandling/BehandlingFormDialog/helpers';
import useMeta from '../../../../hooks/meta/useMeta';
import MenuButton from './MenuButton';

type Props = {selectedPosition?: {x: number; y: number}};

function PositionMenu({selectedPosition}: Props) {
    const meta = useMeta();
    const [selectedPositionOpen, setSelectedPositionOpen] =
        React.useState(true);

    return (
        <div style={{marginTop: 20}}>
            <MenuButton
                name='Registrert posisjon'
                Icon={<MapRoutePins1Icon />}
                onClick={() => setSelectedPositionOpen(!selectedPositionOpen)}
                isOpen={selectedPositionOpen}
            />
            {selectedPositionOpen && (
                <div style={{backgroundColor: '#eaeaea', padding: 10}}>
                    <p style={{fontWeight: 500, marginTop: 0}}>
                        {`Koordinat, ${getProj4Def(meta.referansesystemKode)}:`}
                    </p>
                    {selectedPosition && (
                        <p>{`${selectedPosition.x}N ${selectedPosition.y}Ø`}</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default PositionMenu;
