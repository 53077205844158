import {useQuery} from 'react-query';
import useErrorHandler from '../errors/useErrorHandler';

type ConsentObjectProps = {marketing: boolean; statistic: boolean} | undefined;

const getCookieConsent = () => {
    const marketingConsentGiven = CookieInformation.getConsentGivenFor(
        'cookie_cat_marketing'
    );
    const statisticConsentGiven = CookieInformation.getConsentGivenFor(
        'cookie_cat_statistic'
    );

    const consent: ConsentObjectProps = {
        marketing: marketingConsentGiven,
        statistic: statisticConsentGiven,
    };

    return consent;
};

export default function useCookieConsent() {
    const {notify} = useErrorHandler();
    const query = useQuery<ConsentObjectProps>(
        ['cookieConsent'],
        () => getCookieConsent(),
        {
            enabled: true,
            onError: notify,
            staleTime: undefined,
            cacheTime: undefined,
        }
    );

    return {
        ...query,
        data: query.data || ({} as ConsentObjectProps),
        marketing: query.data?.marketing,
        statistic: query.data?.statistic,
    };
}
