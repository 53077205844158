import React from 'react';
import {Autocomplete, AutocompleteProps, TextField} from '@mui/material';
import _ from 'lodash-es';
import {useState} from 'react';
import {ChevronDownIcon} from '@norkart/toi-icons';

export type FilterableDropdownProps<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> = Pick<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | 'getOptionLabel'
    | 'options'
    | 'onBlur'
    | 'onFocus'
    | 'className'
    | 'disabled'
    | 'id'
> & {
    keyCol: string;
    valueCol: string;
    value: string;
    onChange: (value: string) => void;
    error?: boolean;
    nullOption?: boolean;
    displayId?: boolean;
};

export function FilterableDropdown<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({
    options,
    keyCol,
    valueCol,
    getOptionLabel,
    value,
    onChange,
    nullOption,
    displayId,
    ...props
}: FilterableDropdownProps<T, Multiple, DisableClearable, FreeSolo>) {
    const indexOfSelectedOption = _.findIndex(
        options,
        (o) => o[keyCol] === value
    );
    const [selectedOption, setSectedOption] = useState<any>(
        options[indexOfSelectedOption]
    );

    const getOptionLabelCustom = (option) => {
        if (getOptionLabel) return getOptionLabel(option);
        if (displayId) return `${option[keyCol]} - ${option[valueCol]}`;
        return `${option[valueCol]}`;
    };
    return (
        <Autocomplete
            selectOnFocus={true}
            clearOnBlur={true}
            disableClearable={!nullOption}
            options={options}
            openText={'Åpne'}
            popupIcon={<ChevronDownIcon />}
            closeText={'Lukke'}
            getOptionLabel={getOptionLabelCustom}
            onChange={(e, newOption) => {
                const newId = (newOption && newOption[keyCol]) || undefined;
                setSectedOption(newOption);
                onChange(newId);
            }}
            value={selectedOption ? selectedOption : null}
            isOptionEqualToValue={(option, value) =>
                option[keyCol] == value[keyCol]
            }
            noOptionsText='Ingen alternativer'
            fullWidth={true}
            renderInput={(params) => <TextField {...params} />}
            componentsProps={{
                paper: {
                    sx: (theme) => ({
                        '.Mui-focused': {
                            backgroundColor: 'white !important',
                            color: `${theme.palette.secondary.dark}`,
                            outline: '1px dashed black',
                            ...(props.error && {
                                outline: 0,
                            }),
                        },
                        '.MuiAutocomplete-option': {
                            width: '99%',
                            margin: '0 auto',
                            ':hover': {
                                backgroundColor: 'white',
                                color: `${theme.palette.secondary.dark}`,
                                outline: 'unset',
                            },
                        },
                    }),
                },
            }}
            sx={(theme) => ({
                backgroundColor: 'white',
                '.MuiAutocomplete-input': {
                    paddingTop: '2.5px !important',
                    paddingBottom: '2.5px !important',
                    '&:hover': {color: `${theme.palette.secondary.dark}`},
                    '&.Mui-focused ': {
                        color: `${theme.palette.secondary.dark}`,
                    },
                },
                '.MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.primary.dark}`,
                    borderRadius: 0,
                    ...(props.error && {
                        border: `1px solid ${theme.palette.error.main}!important`,
                        outline: 'unset',
                    }),
                },
                '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: `1px dashed ${theme.palette.primary.dark} !important`,
                    ...(props.error && {
                        border: `1px solid ${theme.palette.error.main}!important`,
                    }),
                    color: `${theme.palette.secondary.dark}`,
                },
                '& .MuiAutocomplete-inputRoot': {
                    paddingTop: '2.5px',
                    paddingBottom: '2.5px',
                    paddingLeft: '4px',
                },
            })}
            {...props}
        />
    );
}

export default FilterableDropdown;
