import {useMutation, useQueryClient} from 'react-query';
import {deleteDokument} from '../../services/api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

export default function useDeleteDokument() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {notify} = useErrorHandler();

    return useMutation((id: number) => deleteDokument(id), {
        onError: notify,
        onSuccess: () => {
            queryClient.invalidateQueries([
                'dokumenter',
                {kundeId, arealplanId},
            ]);
            queryClient.invalidateQueries([
                'behandlinger',
                {kundeId, arealplanId},
            ]);
            queryClient.invalidateQueries([
                'dispensasjoner',
                {kundeId, arealplanId},
            ]);
        },
    });
}
