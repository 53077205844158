import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog} from '../../../store/dialog/actions';
import {Arealplan} from '../../../hooks/arealplaner/types';
import PlanRelationshipForm from '../PlanRelationshipForm';
import SpinnerButton from '../../../components/SpinnerButton';

export interface PlanRelationshipFormDialogProps extends ConnectedReduxProps {
    plan: Arealplan;
    open: boolean;
}

const PlanRelationshipFormDialog = ({
    plan,
    open,
    dispatch,
}: PlanRelationshipFormDialogProps) => {
    const [submitting, setSubmitting] = React.useState(false);

    const onClose = async () => {
        return hideDialog(dispatch);
    };

    const handleSubmit = () => {
        document
            .getElementById('relationship-form')!
            .dispatchEvent(
                new Event('submit', {cancelable: true, bubbles: true})
            );
    };

    return (
        <Dialog
            open={open}
            aria-labelledby='form-dialog-title'
            maxWidth='lg'
            fullWidth={true}
            PaperProps={{style: {overflowY: 'visible'}}}
            disableAutoFocus={true}
        >
            <DialogTitle id='form-dialog-title'>
                <Grid
                    container={true}
                    justifyContent='space-between'
                    spacing={8}
                    alignItems='center'
                >
                    <Grid item={true}>Planforhold</Grid>
                    <Grid item={true}>
                        <IconButton onClick={onClose}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{minHeight: '350px'}}>
                <PlanRelationshipForm
                    plan={plan}
                    formId='relationship-form'
                    setSubmitting={setSubmitting}
                    onClose={onClose}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary' onClick={onClose}>
                    Avbryt
                </Button>

                <SpinnerButton
                    label='Lagre'
                    loading={submitting}
                    disabled={submitting}
                    onClick={handleSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export default PlanRelationshipFormDialog;
