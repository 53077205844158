import {createTheme, ThemeOptions} from '@mui/material/styles';
import {deepmerge} from '@mui/utils';
import {toiPalette} from './toiPalette';
import './fonts';

export const DEFAULT_TYPOGRAPHY_VARIANT = 'body2' as const;
export type DefaultTypographyVariantOption = 'body1' | 'body2' | 'body3';

export interface ToiThemeOptions extends ThemeOptions {
  components?: ThemeOptions['components'] & {
    ToiTypography?: {
      defaultProps: {
        variant: DefaultTypographyVariantOption;
      };
    };
  };
}

export const toiTheme: ToiThemeOptions = {
  typography: {
    fontFamily: 'Figtree',
  },
  palette: toiPalette,
  shape: {
    borderRadius: 0,
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    ToiTypography: {
      defaultProps: {
        variant: DEFAULT_TYPOGRAPHY_VARIANT,
      },
    },
  },
};

export const createToiTheme = (overrides?: ToiThemeOptions) => {
  return createTheme(overrides ? deepmerge(toiTheme, overrides) : toiTheme);
};
