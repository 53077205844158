import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import {useEffect, useState} from 'react';
import React from 'react';
import {toiColors} from '@norkart/toi-colors';
import {
    ToiButton,
    ToiIconButton,
    ToiTextField,
    ToiToolTip,
} from '@norkart/toi-components';
import {AppSendIcon} from '@norkart/toi-icons';
import {ConversationType} from '../types';

type Props = {
    disabled: boolean;
    clearOnSend: boolean;
    onSendChat: (q: string) => void;
    exampleQuestion: string;
    setExampleQuestion: (q: string) => void;
    onAbortMutation: () => void;
    isLoading: boolean;
    conversationType: ConversationType;
};

export const QuestionInput = React.forwardRef(
    (
        {
            disabled,
            clearOnSend,
            onSendChat,
            exampleQuestion,
            setExampleQuestion,
            onAbortMutation,
            isLoading,
            conversationType,
        }: Props,
        forwardedRef: React.ForwardedRef<HTMLDivElement>
    ) => {
        const [question, setQuestion] = useState<string>('');
        const sendQuestionDisabled = disabled || !question.trim();

        useEffect(() => {
            if (exampleQuestion !== '') {
                setQuestion(exampleQuestion);
            }
        }, [exampleQuestion]);

        const sendQuestion = () => {
            if (sendQuestionDisabled) {
                return;
            }
            setExampleQuestion('');
            onSendChat(question);

            if (clearOnSend) {
                setQuestion('');
            }
        };

        const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
            if (ev.key === 'Enter' && !ev.shiftKey) {
                ev.preventDefault();
                sendQuestion();
            }
        };

        const onQuestionChange = (newValue?: string) => {
            setQuestion(newValue || '');
        };

        const buttonEndAdornment = {
            endAdornment: (
                <InputAdornment position='end'>
                    {isLoading ? (
                        <ToiButton
                            size='medium'
                            variant='outlined'
                            color='secondary'
                            startIcon={<StopCircleOutlinedIcon />}
                            onClick={onAbortMutation}
                        >
                            Stopp søket
                        </ToiButton>
                    ) : (
                        <ToiToolTip title='Send'>
                            <span>
                                <ToiIconButton
                                    aria-label='Send spørsmål'
                                    onClick={sendQuestion}
                                    disabled={sendQuestionDisabled}
                                >
                                    <AppSendIcon
                                        secondaryStyle={{
                                            fill: sendQuestionDisabled
                                                ? toiColors.black[60]
                                                : toiColors.black[100],
                                        }}
                                    />
                                </ToiIconButton>
                            </span>
                        </ToiToolTip>
                    )}
                </InputAdornment>
            ),
        };

        return (
            <ToiTextField
                fullWidth
                multiline
                inputRef={forwardedRef}
                placeholder={conversationType.placeholder}
                value={question}
                onChange={(e) => onQuestionChange(e.target.value)}
                onKeyDown={onEnterPress}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0px !important',
                        padding: '16px 24px',
                        input: {p: 0},
                    },
                    mr: 2,
                    width: '100%',
                    mb: 4,
                }}
                InputProps={{
                    ...buttonEndAdornment,
                }}
            />
        );
    }
);
