export type Device =
    | 'mobilePortrait'
    | 'mobileLandscape'
    | 'tablet'
    | 'laptop'
    | 'desktop';

// The max width of device
export const breakpoints: Record<Device, number> = {
    mobilePortrait: 480,
    mobileLandscape: 768,
    tablet: 1024,
    laptop: 1200,
    desktop: 1920,
};
