import {Box} from '@mui/material';
import React from 'react';
import {ChevronDownIcon, ChevronRightIcon} from '@norkart/toi-icons';
import {hoverOverWhiteColor} from '../../../../theme';

type Props = {
    name: string;
    description?: JSX.Element;
    onClick;
    className?: string;
    isOpen: boolean;
    Icon: JSX.Element;
    warningBorder?: boolean;
    cannotBeClosed?: boolean;
};

/**Knappen for å åpne/lukke meny */
const MenuButton = ({
    name,
    onClick,
    isOpen,
    Icon,
    description,
    warningBorder,
    cannotBeClosed,
    className,
}: Props) => {
    return (
        <Box
            className='section'
            sx={(theme) => ({
                button: {
                    width: '100%',
                    display: 'flex',
                    cursor: 'pointer',
                    border: '1px solid #0000003d',
                    backgroundColor: 'white',
                    padding: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:focus': {
                        outline: 'none',
                        border: '2px solid ' + theme.palette.secondary.main,
                    },
                    '&:hover': {
                        backgroundColor: hoverOverWhiteColor,
                    },
                    '& p': {
                        margin: 0,
                        marginLeft: '15px',
                        fontSize: 18,
                    },
                    ...(warningBorder && {
                        border: '4px solid #f5b136',
                        '&:focus': {
                            outline: 'none',
                        },
                    }),
                },
            })}
        >
            <button className={className} onClick={onClick}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: '5px',
                            }}
                        >
                            {Icon}
                            <p>{name}</p>
                        </div>
                        {!cannotBeClosed ? (
                            isOpen ? (
                                <ChevronDownIcon />
                            ) : (
                                <ChevronRightIcon />
                            )
                        ) : (
                            ''
                        )}
                    </div>
                    {description && (
                        <div
                            style={{
                                marginTop: 10,
                                marginLeft: 40,
                                textAlign: 'left',
                                fontSize: 14,
                                maxWidth: 300,
                            }}
                        >
                            {description}
                        </div>
                    )}
                </div>
            </button>
        </Box>
    );
};
export default MenuButton;
