import {useAccount} from '@azure/msal-react';
import {useNavigate} from 'react-router-dom';
import {useIsLoggedIn, useNkAuth} from '@norkart/nk-auth';
import {
    ToiAppBar,
    ToiAppBarLogin,
    ToiBox,
    ToiTypography,
} from '@norkart/toi-components';
import {ToiThemeProvider} from '@norkart/toi-theme';
import {useAuth} from '../../utils/auth';
import TopbarActions from './components/TopbarActions';
import TopbarKommuneHeader from './components/TopbarKommuneHeader';

export const Topbar = () => {
    const auth = useAuth();
    const account = useAccount();
    const isLoggedIn = useIsLoggedIn();
    const {customerContextData} = useNkAuth();
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };

    return (
        <ToiThemeProvider>
            <ToiAppBar
                title='Arealplaner'
                sx={(theme) => ({zIndex: theme.zIndex.drawer + 1})}
            >
                <ToiTypography
                    color='textPrimary'
                    component='a'
                    variant='h3'
                    onClick={handleHome}
                    sx={{cursor: 'pointer'}}
                >
                    Arealplaner
                </ToiTypography>
                <ToiBox sx={{pl: '30px'}} />
                <TopbarKommuneHeader />
                <ToiBox sx={{flexGrow: 1}} />
                <TopbarActions />
                <ToiBox sx={{pl: '30px'}} />
                <ToiAppBarLogin
                    isLoggedIn={isLoggedIn}
                    name={account?.name}
                    login={auth.login}
                />
            </ToiAppBar>
        </ToiThemeProvider>
    );
};

export default Topbar;
