import {useMutation, useQueryClient} from 'react-query';
import {updateDispensasjon} from '../../services/api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {DispensasjonFormData} from './types';

type Props = {id: number; dispensasjon: DispensasjonFormData};

const editDispensasjon = async ({id, dispensasjon}: Props) => {
    const {data} = await updateDispensasjon(id, dispensasjon);
    return data;
};

export default function useUpdateDispensasjon() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    return useMutation(editDispensasjon, {
        onSuccess: (data, props) => {
            queryClient.invalidateQueries([
                'dispensasjoner',
                {kundeId, arealplanId: props.dispensasjon.arealplanId},
            ]);
        },
    });
}
