import {styled} from '@mui/material';
import {forwardRef} from 'react';
import ToiButton from '../ToiButton/ToiButton';

import {toiAppBarLoginStyle} from './toiAppBarLoginStyle';
import {ToiUserCard, ToiUserCardProps} from './ToiUserCard';

export {ToiUserCard} from './ToiUserCard';

export interface ToiAppBarLoginProps extends ToiUserCardProps {
  //Add your own custom props here.
  isLoggedIn?: boolean;
  login?: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

// change div to what you want to style
const StyledComponent = styled(ToiButton)(toiAppBarLoginStyle, {
  // shouldForwardProp: (prop) => !['ownProp1', 'ownProp2'].includes(prop.toString()),    // <-- add this to prevent custom props to be forwarded to the html component
});

export const ToiAppBarLogin = forwardRef<HTMLButtonElement, ToiAppBarLoginProps>((props, forwardedRef) => {
  const {login, isLoggedIn, ...rest} = props;
  return (
    <StyledComponent color='secondary' variant='text' ref={forwardedRef} {...rest}>
      {props.isLoggedIn ? (
        <ToiUserCard name={props.name} company={props.company} />
      ) : (
        <ToiButton onClick={props.login} sx={{}}>
          Logg inn
        </ToiButton>
      )}
    </StyledComponent>
  );
});

export default ToiAppBarLogin;
