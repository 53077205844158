import {useQuery} from 'react-query';
import {fetchArkivKodeliste} from '../../services/api';
import {KodelisteType, KodeVerdi} from './types';

const getKodeliste = async (arkivId: number, kodelisteType: KodelisteType) => {
    const {data} = await fetchArkivKodeliste(arkivId, kodelisteType);
    return data as KodeVerdi[];
};

export function useArkivKodeliste(
    arkivId: number,
    kodelisteType: KodelisteType
) {
    const query = useQuery<KodeVerdi[]>(
        ['arkivkodeliste', arkivId, kodelisteType],
        () => getKodeliste(arkivId, kodelisteType),
        {refetchOnWindowFocus: false}
    );
    return {...query, data: query?.data || ([] as KodeVerdi[])};
}
