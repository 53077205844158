import {useMutation, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {createArealplan} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Arealplan} from './types';

const addArealplan = async (arealplan: Arealplan): Promise<Arealplan> => {
    const {data} = await createArealplan(arealplan);
    return data;
};

export default function useCreateArealplan() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const navigate = useNavigate();

    return useMutation(addArealplan, {
        onError: (error) => {
            notify(error);
        },
        onSuccess: (arealplan) => {
            queryClient.invalidateQueries([
                'arealplan',
                {kundeId, arealplanId: arealplan.id},
            ]);
            navigate(`/${kundeId}/arealplaner/${arealplan.id}`);
        },
    });
}
