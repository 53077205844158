import {useQuery} from 'react-query';
import {fetchApplikasjonInnstillinger} from '../../services/api';
import {ApplikasjonInnstilling} from './types';

export default function useApplikasjonInnstillinger() {
    const query = useQuery<ApplikasjonInnstilling[], any>(
        ['applikasjonInnstillinger'],
        () =>
            fetchApplikasjonInnstillinger().then(
                (res) => res.data as ApplikasjonInnstilling[]
            )
    );

    return {
        ...query,
        data: query?.data || [],
    };
}
