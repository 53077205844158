import {ErrorOutline} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RearrangeIcon from '@mui/icons-material/ImportExport';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import {Box, Button, Dialog, Menu, MenuItem, Typography} from '@mui/material';
import {saveAs} from 'file-saver';
import JSZip from 'jszip';
import React from 'react';
import {useDispatch} from 'react-redux';
import {
    AppDownloadIcon,
    SortingDownIcon,
    AppDeleteRecycleIcon,
} from '@norkart/toi-icons';
import ActionButtons from '../../../components/ActionButtons';
import {ActionButtonProps} from '../../../components/ActionButtons/ActionButton';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {Arealplan} from '../../../hooks/arealplaner/types';
import useDokuments from '../../../hooks/dokumenter/useDokuments';
import useMeta from '../../../hooks/meta/useMeta';
import {showDialog} from '../../../store/dialog/actions';
import {SelectedDocument} from '../../../store/selectedDocumentsTable/reducer';
import useCan from '../../../utils/auth/useCan';
import DeleteDocumentsDialog from './DeleteDocumentsDialog';

type Props = {
    selectedDocuments: SelectedDocument[];
    plan: Arealplan;
    handleDocumentFromCatalog: () => void;
    handleDocumentFromArchive?: () => void;
    handleRearrangeDocuments?: () => void;
};

const TableMenu = ({
    selectedDocuments,
    plan,
    handleDocumentFromCatalog,
    handleDocumentFromArchive,
    handleRearrangeDocuments,
    ...props
}: Props) => {
    const meta = useMeta();
    const can = useCan();
    const dispatch = useDispatch();
    const {getDokumentUrl} = useDokuments();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [downloadError, setDownloadError] = React.useState<boolean>(false);
    const [deleteDialogVisible, setDeleteDialogVisible] =
        React.useState<boolean>(false);
    const [anchorElement, setAnchorElement] = React.useState<
        Element | undefined
    >(undefined);
    const isEdge = window.navigator.userAgent.indexOf('Edge') > -1;

    const createZipFile = (blobs: any[]) =>
        blobs.reduce((zip: any, blob: any) => {
            zip.file(blob.filename, blob.blob, {
                binary: true,
            });
            return zip;
        }, new JSZip());

    const downloadFile = async (documentId: number, filename: string) => {
        const url = await getDokumentUrl(documentId);
        if (url) {
            const response = await fetch(url);
            const blob = await response.blob();
            if (isEdge) {
                saveAs(blob, filename);
            } else {
                return {blob, filename};
            }
        }
    };

    const downloadSelectedDocuments = () => {
        setLoading(true);
        setDownloadError(false);
        const downloadFiles = async (
            docs: SelectedDocument[],
            filename: string
        ) => {
            try {
                const blobs = await Promise.all(
                    docs.map((doc) =>
                        downloadFile(
                            doc.dokumentId,
                            doc.filepath + doc.filename
                        )
                    )
                );
                if (!isEdge) {
                    const zipData = await createZipFile(blobs).generateAsync({
                        type: 'blob',
                    });
                    saveAs(zipData, filename);
                }
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                setDownloadError(true);
                setTimeout(function () {
                    setDownloadError(false);
                }, 5000);
            }
        };
        downloadFiles(
            selectedDocuments,
            'plan-' + plan.komnr + '_' + plan.planId + '-dokumenter'
        );
    };

    const moveSelectedDocuments = () => {
        //open move dialog
        dispatch(
            showDialog({
                dialogType: 'SELECT_BEHANDLING',
                dialogProps: {
                    selectedDocuments,
                    plan,
                },
            })
        );
    };

    const handleOnDelete = () => {
        //open delete dialog
        setDeleteDialogVisible(true);
    };

    const hasSelected = selectedDocuments.length > 0;
    const menuButtons = [
        {
            icon: <AppDownloadIcon />,
            title: 'Last ned valgte dokumenter',
            label: 'Last ned',
            onClick: downloadSelectedDocuments,
        },
    ];
    if (can.edit) {
        menuButtons.push({
            icon: <AppDeleteRecycleIcon />,
            title: 'Slett valgte dokumenter',
            label: 'Slett',
            onClick: handleOnDelete,
        });
        menuButtons.push({
            icon: <SortingDownIcon />,
            title: 'Flytt valgte dokumenter',
            label: 'Flytt til',
            onClick: moveSelectedDocuments,
        });
    }

    const handleMenu = (event) => {
        setAnchorElement(event.currentTarget);
        // setOpenMenu(true)
    };
    const handleClose = () => setAnchorElement(undefined);

    const actionButtons = [
        {
            title: <MenuIcon />,
            label: 'Flere handlinger',
            onClick: handleMenu,
        },
    ] as ActionButtonProps[];

    return (
        <Box
            sx={{
                backgroundColor: '#EFEFEF',
                height: '60px',
                display: 'flex',
                marginBottom: '20px',
            }}
        >
            <div style={{display: 'flex'}}>
                {menuButtons.map((btn, index) => (
                    <Button
                        key={index}
                        startIcon={btn.icon}
                        title={btn.title}
                        disabled={!hasSelected}
                        onClick={btn.onClick}
                        sx={{
                            marginRight: '14px',
                            marginLeft: '14px',
                            '&:disabled': {
                                opacity: 0.5,
                            },
                        }}
                    >
                        {btn.label}
                    </Button>
                ))}
            </div>
            <div style={{marginLeft: 'auto'}} id='more-options'>
                {can.edit && <ActionButtons actionButtons={actionButtons} />}
            </div>
            <DeleteDocumentsDialog
                plan={plan}
                open={deleteDialogVisible}
                onClose={() => setDeleteDialogVisible(false)}
            />
            <Dialog open={loading} fullWidth={true}>
                <LoadingSpinner text={'Laster ned valgte dokumenter'} />
            </Dialog>
            <Dialog
                open={downloadError}
                fullWidth={true}
                onClose={() => setDownloadError(false)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        padding: '23px',
                    }}
                >
                    <ErrorOutline />
                    <Typography
                        sx={{
                            paddingLeft: '15px',
                            paddingTop: '2px',
                        }}
                    >
                        Nedlastningen feilet, prøv igjen.
                    </Typography>
                </Box>
            </Dialog>
            <Menu
                id='add-document'
                anchorEl={anchorElement}
                open={Boolean(anchorElement)}
                onClose={() => handleClose()}
                disableScrollLock={true}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleDocumentFromCatalog();
                    }}
                >
                    <>
                        <AddIcon /> Legg til plandokument
                    </>
                </MenuItem>
                {handleDocumentFromArchive && meta.hasArkivIntegrasjon() && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleDocumentFromArchive();
                        }}
                    >
                        <>
                            <AddIcon /> Legg til plandokument fra arkiv
                        </>
                    </MenuItem>
                )}
                {handleRearrangeDocuments && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleRearrangeDocuments();
                        }}
                    >
                        <>
                            <RearrangeIcon /> Endre dokumentrekkefølge
                        </>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default TableMenu;
