import React from 'react';
import {ToiStack, ToiTypography} from '@norkart/toi-components';
import loadingAnimation from '../assets/animations/chat_stars_animation.gif';
import {chatHistoryItem} from '../cache/chatHistory';
import {ConversationType} from '../types';
import {ChatWindow} from './ChatWindow';
import {ExampleQuestions} from './ExampleQuestion';

type Props = {
    selectedConversation: ConversationType;
    conversationConfig: ConversationType[];
    chatHistory: chatHistoryItem[];
    isFetching: boolean;
    hasError: boolean;
    errorText: string;
    botName: string;
    setExampleQuestion: (question: string) => void;
    chatInputRef?: React.RefObject<HTMLDivElement>;
    displaySearchedIndicesText?: boolean;
};
export const ChatField = (props: Props) => {
    const {
        chatHistory,
        isFetching,
        hasError,
        errorText,
        setExampleQuestion,
        conversationConfig,
        chatInputRef,
        displaySearchedIndicesText = true,
    } = props;

    return (
        <ToiStack alignItems={'center'} gap={4} width={'100%'}>
            <ToiStack gap={2} pb={4} width={'100%'}>
                <ToiStack>
                    <ToiStack direction='row' flexWrap='wrap' gap={2} mb={4}>
                        <ExampleQuestions
                            setExampleQuestion={setExampleQuestion}
                            selectedConversation={props.selectedConversation}
                            chatInputRef={chatInputRef}
                        />
                    </ToiStack>
                    <ChatWindow
                        botName={props.botName}
                        chatHistory={chatHistory ?? []}
                        conversationConfig={conversationConfig}
                        displaySearchedIndicesText={displaySearchedIndicesText}
                    />
                    {isFetching && (
                        <ToiStack
                            paddingTop={4}
                            paddingBottom={2}
                            flexDirection={'row'}
                            gap={2}
                            alignItems={'center'}
                        >
                            <img
                                src={loadingAnimation}
                                height={32}
                                width={32}
                                alt='Loading'
                            />
                            <ToiTypography>
                                {props.selectedConversation.loadingText}
                            </ToiTypography>
                        </ToiStack>
                    )}
                    {hasError && (
                        <ToiTypography color={'error'} mt={2}>
                            {errorText}
                        </ToiTypography>
                    )}
                </ToiStack>
            </ToiStack>
        </ToiStack>
    );
};
