import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  IconButtonTypeMap as MuiIconButtonTypeMap,
  styled,
} from '@mui/material';
import React from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {toiIconButtonStyle} from './toiIconButtonStyle';

type OwnProps = {
  /** @deprecated Add the icon as a child component instead */
  Icon?: React.ReactNode;
  'aria-label': React.ComponentProps<'button'>['aria-label'];
};

type DefaultComponent = MuiIconButtonTypeMap['defaultComponent'];

type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiIconButtonProps = MuiIconButtonProps<DefaultComponent, OwnProps>;

const StyledIconButton = styled(MuiIconButton)(toiIconButtonStyle);

export const ToiIconButton = React.forwardRef<RefType, ToiIconButtonProps>((props, ref) => {
  const {children, Icon, ...rest} = props;

  return (
    <StyledIconButton ref={ref} {...rest}>
      {Icon ? Icon : children}
    </StyledIconButton>
  );
}) as OverridableComponent<MuiIconButtonTypeMap<OwnProps>>;

export default ToiIconButton;
