import {useMutation, useQueryClient} from 'react-query';
import {updateArkivKodelisteTilganger} from '../../services/api';
import {KodelisteTilgang, KodelisteType} from './types';

export default function useUpdateArkivKodelisteTilganger(
    arkivId: number,
    kodelisteType: KodelisteType
) {
    const queryClient = useQueryClient();

    return useMutation(
        async (data: KodelisteTilgang[]) =>
            await updateArkivKodelisteTilganger(arkivId, kodelisteType, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'arkivkodelistetilganger',
                    arkivId,
                    kodelisteType,
                ]);
            },
        }
    );
}
