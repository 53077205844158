import {Dialog as MuiDialog, DialogProps as MuiDialogProps, styled} from '@mui/material';
import React, {forwardRef, useCallback} from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {AppCloseIcon} from '@norkart/toi-icons';
import ToiIconButton from '../ToiIconButton/ToiIconButton';
import {toiDialogStyle} from './toiDialogStyle';

type OwnProps = {
  onClose?: (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton') => void;
  hasCloseButton?: boolean;
  closeIconButtonAriaLabel?: string;
};

export type ToiDialogProps = Omit<MuiDialogProps, keyof OwnProps | 'component'> & OwnProps;

const StyledDialog = styled(MuiDialog)(({theme}) => toiDialogStyle(theme));

export const ToiDialog = forwardRef<HTMLDivElement, ToiDialogProps>((props, ref) => {
  const {children, hasCloseButton = true, closeIconButtonAriaLabel = 'Lukk', onClose, ...restProps} = props;

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClose && onClose(event, 'closeButton'),
    [onClose]
  );

  return (
    <StyledDialog ref={ref} onClose={onClose} {...restProps}>
      {onClose && hasCloseButton && (
        <ToiIconButton
          sx={{position: 'absolute', top: 8, right: 8}}
          onClick={handleClose}
          aria-label={closeIconButtonAriaLabel}
        >
          <AppCloseIcon />
        </ToiIconButton>
      )}
      {children}
    </StyledDialog>
  );
});

export default ToiDialog;
