import {useMutation, useQueryClient} from 'react-query';
import {updateArealplan} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Arealplan} from './types';

type Props = Arealplan & {updateQMS?: boolean};

const editArealplan = async (props: Props): Promise<Arealplan> => {
    const {data} = await updateArealplan(props);
    return data;
};

export default function useUpdateArealplan() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();

    return useMutation(editArealplan, {
        onError: notify,
        onSuccess: (_, props) => {
            queryClient.invalidateQueries([
                'arealplan',
                {kundeId, arealplanId: props.id},
            ]);
        },
    });
}
