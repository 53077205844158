import {Palette} from '@mui/material';
import {toiColors} from '@norkart/toi-colors';
import {IconProps, MetaProps} from './SvgIcon';

export const getIconStyles = (palette: Palette, propsOfIcon: IconProps & MetaProps) => {
  const {mode = palette.mode, blackWhite, singleColor} = propsOfIcon;

  const isSingleColorCssVariable = typeof singleColor === 'string' && /^var\(--[\w-]+\)$/i.test(singleColor);
  if (isSingleColorCssVariable) {
    return {
      primaryStyle: {fill: singleColor},
      secondaryStyle: {fill: singleColor},
      tertiaryStyle: {fill: singleColor},
    };
  }

  const isSingleColorHex = typeof singleColor === 'string' && /^#([0-9a-f]{3}){1,2}$/i.test(singleColor);

  if (singleColor && !isSingleColorHex) {
    console.warn(`${singleColor} is not a valid color`);
  }

  const blackWhiteScheme = {
    light: {
      primaryStyle: {fill: `${palette.black?.[140] ?? '#000000'}`},
      secondaryStyle: {fill: `${palette.black?.[140] ?? '#000000'}`},
      tertiaryStyle: {fill: `${palette.black?.[140] ?? '#000000'}`},
    },
    dark: {
      primaryStyle: {fill: `${palette.white ?? '#ffffff'}`},
      secondaryStyle: {fill: `${palette.white ?? '#ffffff'}`},
      tertiaryStyle: {fill: `${palette.white ?? '#ffffff'}`},
    },
  }[mode];

  const singleColorScheme = {
    light: {
      primaryStyle: {fill: singleColor},
      secondaryStyle: {fill: singleColor},
      tertiaryStyle: {fill: singleColor},
    },
    dark: {
      primaryStyle: {fill: singleColor},
      secondaryStyle: {fill: singleColor},
      tertiaryStyle: {fill: singleColor},
    },
  }[mode];

  const toiThemeScheme = {
    light: {
      primaryStyle: {fill: `${palette.green?.[80] ?? toiColors.green[80]}`},
      secondaryStyle: {fill: `${palette.black?.[100] ?? toiColors.black[100]}`},
      tertiaryStyle: {fill: `${palette.black?.[100] ?? toiColors.black[100]}`, opacity: 0.3},
    },
    dark: {
      primaryStyle: {fill: `${palette.green?.[80] ?? toiColors.green[80]}`},
      secondaryStyle: {fill: `${palette.white ?? '#ffffff'}`},
      tertiaryStyle: {fill: `${palette.white ?? '#ffffff'}`, opacity: 0.4},
    },
  }[mode];

  const scheme = blackWhite ? blackWhiteScheme : isSingleColorHex ? singleColorScheme : toiThemeScheme;

  const primaryStyle = propsOfIcon.primaryStyle || scheme.primaryStyle;
  const secondaryStyle = propsOfIcon.secondaryStyle || scheme.secondaryStyle;
  const tertiaryStyle = propsOfIcon.tertiaryStyle || scheme.tertiaryStyle;

  return {primaryStyle, secondaryStyle, tertiaryStyle};
};
