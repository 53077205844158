import {useApplicationInsights} from '@norkart/core-applicationinsights';

export default function useTrackArealplanerEvent(
    component: string,
    eventType: 'button' | 'event'
) {
    const appInsights = useApplicationInsights();

    return (evData: string) => {
        if (evData !== '' && appInsights) {
            appInsights.trackEvent({name: component}, {[eventType]: evData});
        }
    };
}
