import {useMutation, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {deleteArealplan} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

export default function useDeleteArealplan() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const navigate = useNavigate();

    return useMutation((id: number) => deleteArealplan(id), {
        onError: notify,
        onSuccess: (data, id) => {
            navigate(`/${kundeId}/`);
            queryClient.removeQueries([
                'arealplan',
                {kundeId, arealplanId: id},
            ]);
            queryClient.removeQueries([
                'dokumenter',
                {kundeId, arealplanId: id},
            ]);
            queryClient.removeQueries([
                'behandlinger',
                {kundeId, arealplanId: id},
            ]);
            queryClient.removeQueries([
                'dispensasjoner',
                {kundeId, arealplanId: id},
            ]);
            queryClient.removeQueries([
                'midlertidigforbud',
                {kundeId, arealplanId: id},
            ]);
        },
    });
}
