import * as React from 'react';
import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {NkAzureADAuthProvider} from '@norkart/nk-auth';
import Application from './application';
import './bodystyle.css';
import theme from './theme';
import {azureADAuthProviderConfiguration} from './utils/auth';
import {ApplicationState} from './store';
import setupStore from './setupStore';
import {ConfigureAxiosInterceptors} from './configureAxiosInterceptors';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 10, // 10 min
            cacheTime: 1000 * 60 * 30, //  30 min
        },
    },
});

const initialState: ApplicationState = window['initialReduxState'];
export const store = setupStore(initialState);

function Main() {
    return (
        <NkAzureADAuthProvider configuration={azureADAuthProviderConfiguration}>
            <ConfigureAxiosInterceptors>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <QueryClientProvider client={queryClient}>
                                <CssBaseline />
                                <BrowserRouter>
                                    <Application />
                                </BrowserRouter>
                                <ReactQueryDevtools initialIsOpen={false} />
                            </QueryClientProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </Provider>
            </ConfigureAxiosInterceptors>
        </NkAzureADAuthProvider>
    );
}

export default Main;
