import {useMutation, useQueryClient} from 'react-query';
import {updateDokument} from '../../services/api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Dokument} from './types';

const editDokument = async (dokument: Dokument): Promise<Dokument> => {
    const {data} = await updateDokument(dokument);
    return data;
};

export default function useUpdateDokument() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();

    return useMutation(editDokument, {
        onSuccess: () => {
            // Må invalidate fordi rekkefølge blir ikke returnert ved oppdatering av dokument
            queryClient.invalidateQueries([
                'behandlinger',
                {kundeId, arealplanId},
            ]);
            queryClient.invalidateQueries([
                'dispensasjoner',
                {kundeId, arealplanId},
            ]);
            queryClient.invalidateQueries([
                'dokumenter',
                {kundeId, arealplanId},
            ]);
        },
    });
}
