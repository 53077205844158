import * as React from 'react';
import {Button, Grid, styled} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {MapboxLayer, Popup, mapClickEvent} from '@norkart/nkm-mapbox-map';
import {AppFullscreenIcon} from '@norkart/toi-icons';
import DesktopContainer from '../../containers/DesktopContainer';
import MobileContainer from '../../containers/MobileContainer';
import {getSearchState} from '../../store';
import {hoverOverWhiteColor} from '../../theme';
import {showDialog} from '../../store/dialog/actions';
import useArealplan from '../../hooks/arealplaner/useArealplan';
import useCan from '../../utils/auth/useCan';
import useDispensasjoner from '../../hooks/dispensasjoner/useDispensasjoner';
import useMeta from '../../hooks/meta/useMeta';
import useTrackArealplanerEvent from '../../hooks/appinsights/useTrackArealplanerEvent';
import {useNavigateToArealplan} from '../../hooks/useNavigateToArealplan';
import {getDispLayers} from './helpers/getDispLayers';
import Kartlag from './Components/Menus/Kartlag/PlanensKartlag';
import ZoomButton from './Components/Menus/ZoomButton';
import {
    createInfoAndButtonPopup,
    createInfoPopup,
    createMapDetailsPopup,
    popupStyle,
} from './helpers/createPopup';
import BaseMap from './BaseMap';

// const MapContainer = styled("div")(({ theme }) => ({
//   height: "100%",
//   minHeight: "100%", //ipad hack, wont set 100 % height
//   width: "100%",
//   position: "relative",
//   margin: 0,
//   padding: 0,
//   top: 0,
//   left: 0,
//   "& .nkm-mapbox-map, .mapboxgl-map": {
//     width: "100%",
//     minHeight: "100%" //ipad hack, wont set 100 % height
//   },
//   [theme.breakpoints.up("md")]: {
//     height: "100%",
//     minHeight: "100%", //ipad hack, wont set 100 % height
//     width: "100%",
//     // position: "relative",
//     "& .nkm-mapbox-map, .mapboxgl-map": {
//       width: "100%",
//       minHeight: "100%" //ipad hack, wont set 100 % height
//     },
//     "& .mapboxgl-ctrl-icon": {
//       width: 44,
//       height: 44
//     },
//     ...popupStyle
//   }
// }))

const MapContainer = styled('div')({
    height: '100%',
    width: '100%',
    position: 'relative',
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    '& .nkm-mapbox-map, .mapboxgl-map': {
        width: '100%',
        height: '100%',
    },
    ...popupStyle,
});

const SeeMapButtonWrapper = styled('div')({
    position: 'absolute',
    top: '8px',
    zIndex: 999,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    '& button': {
        height: '48px',
        width: '200px',
        border: '1px solid #b5b5b5',
        cursor: 'pointer',
        padding: '4px',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        fontSize: 18,
    },
});

const MapButtonsWrapper = styled('div')({
    position: 'absolute',
    top: '8px',
    right: '10px',
    zIndex: 999,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '500px',
});

const DetailsButton = styled(Button)(({theme}) => ({
    height: '46px',
    border: '1px solid #0000003d',
    cursor: 'pointer',
    padding: '4px',
    marginLeft: '15px',
    marginRight: '5px',
    backgroundColor: 'white',
    fontSize: 18,
    fontWeight: 400,
    '& svg': {
        width: '40px',
        height: '36px',
    },
    '&:hover': {
        backgroundColor: hoverOverWhiteColor,
    },
    '&:focus': {
        outline: 'none',
        border: '2px solid ' + theme.palette.secondary.main,
    },
}));

const FlexGrowGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('md')]: {flexGrow: 2},
}));

type props = {
    showDispInMap?: boolean;
};

const PlanMap = ({showDispInMap}: props) => {
    const can = useCan();
    const meta = useMeta();
    const dispatch = useDispatch();
    const searchState = useSelector(getSearchState);
    const navigateArealplan = useNavigateToArealplan();
    const {data: plan} = useArealplan();
    const {data: dispensasjoner} = useDispensasjoner();
    const trackPlanActions = useTrackArealplanerEvent('planButtons', 'button');
    const [mapPopup, setMapPopup] = useState<Popup | undefined>(undefined);
    const [dispLayers, setDispLayers] = useState<MapboxLayer[]>([]);
    const [dispSelected, setDispSelected] = useState<number>(0);
    const [showDispModal, setShowDispModal] = useState<boolean>(false);

    useEffect(() => {
        if (showDispModal) {
            const disp = dispensasjoner.find((b) => b.id == dispSelected);

            dispatch(
                showDialog({
                    dialogType: 'EDIT_DISP',
                    dialogProps: {
                        plan,
                        disp,
                        focusRow: (nr) =>
                            console.log(dispensasjoner.find((b) => b.id == nr)),
                    },
                })
            );
            setShowDispModal(false);
        }
    }, [showDispModal]);

    useEffect(() => {
        if (dispensasjoner.length) {
            if (showDispInMap) {
                const dispToShow = getDispLayers(
                    dispensasjoner,
                    meta.referansesystemKode
                );
                if (JSON.stringify(dispToShow) != JSON.stringify(dispLayers)) {
                    setDispLayers(dispToShow);
                    hideOpenPopups();
                }
            } else {
                setDispLayers([]);
                hideOpenPopups();
            }
        }
    }, [showDispInMap, dispensasjoner]);

    function hideOpenPopups() {
        const x = document.getElementsByClassName('mapboxgl-popup');
        for (let i = x.length - 1; i >= 0; i--) {
            const element = x[i];
            element.parentNode?.removeChild(element);
        }
    }

    const handleFullScreen = () => {
        trackPlanActions('openFullscreenMap');
        navigateArealplan.toArealplanFullscreen(plan.id);
    };

    const showEditDispDialog = () => {
        setShowDispModal(true);

        const element = document.getElementById('disp_popup');
        if (
            element &&
            element.parentElement &&
            element.parentElement.parentElement
        ) {
            element.parentElement.parentElement.remove();
        }
    };

    const handleLayerClick = (e: any) => {
        e.originalEvent.preventDefault();
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        setDispSelected(e.features[0].id);

        if (can.edit) {
            const popup: Popup = createInfoAndButtonPopup(
                e.lngLat,
                description,
                showEditDispDialog
            );
            setMapPopup(popup);
        } else {
            const popup: Popup = createInfoPopup(e.lngLat, description);
            setMapPopup(popup);
        }
    };

    const handleMapClick = (e: mapClickEvent) => {
        if (e.originalEvent.defaultPrevented) return;
        const popup: Popup = createMapDetailsPopup(e.lngLat, handleFullScreen);
        setMapPopup(popup);
    };

    return (
        <Grid
            container={true}
            wrap='nowrap'
            direction='column'
            style={{height: '100%', position: 'relative'}}
        >
            <MobileContainer>
                <FlexGrowGrid item={true} style={{height: '100%'}}>
                    <MapContainer>
                        <SeeMapButtonWrapper>
                            <button
                                onClick={handleFullScreen}
                                title='Trykk for å se kartlag og detaljer om kartet'
                            >
                                Se plankart
                            </button>
                        </SeeMapButtonWrapper>
                        <BaseMap plan={plan} />
                    </MapContainer>
                </FlexGrowGrid>
            </MobileContainer>
            <DesktopContainer>
                <FlexGrowGrid item={true} style={{height: '100%'}}>
                    <MapContainer>
                        <MapButtonsWrapper
                            style={{left: 10, justifyContent: 'flex-start'}}
                        >
                            <ZoomButton type={'plan'} />
                            {searchState.searchedPropertyGeom && (
                                <ZoomButton type={'property'} />
                            )}
                        </MapButtonsWrapper>
                        <MapButtonsWrapper>
                            <Kartlag defaultClosed={true} />
                            <DetailsButton
                                onClick={handleFullScreen}
                                title='Trykk for å se kartlag og detaljer om kartet'
                                endIcon={<AppFullscreenIcon />}
                            >
                                Kartdetaljer
                            </DetailsButton>
                        </MapButtonsWrapper>
                        <BaseMap
                            plan={plan}
                            extraLayers={dispLayers}
                            onMapClick={handleMapClick}
                            onLayerClick={handleLayerClick}
                            clickableLayers={[
                                {name: 'negative-marker'},
                                {name: 'positive-marker'},
                                {name: 'neutral-marker'},
                            ]}
                            mapPopup={mapPopup}
                            displaySearchedPropertyGeom={true}
                        />
                    </MapContainer>
                </FlexGrowGrid>
            </DesktopContainer>
        </Grid>
    );
};
export default PlanMap;
