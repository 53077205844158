import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {orderBy} from 'lodash-es';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import {Dispensasjon} from '../../../hooks/dispensasjoner/types';
import {showDialog} from '../../../store/dialog/actions';
import {setExpandDispensasjonId} from '../../../store/session/actions';
import {ImportDocumentDialogProps} from '../../dokumenter/ImportDocumentDialog/ImportDocumentDialog';

export const useDispensasjonActions = () => {
    const dispatch = useDispatch();
    const {data: plan} = useArealplan();

    const focusRow = useCallback((id: string) => {
        dispatch(setExpandDispensasjonId(id));
    }, []);

    const create = () => {
        const disp = {} as Dispensasjon;
        dispatch(
            showDialog({
                dialogType: 'CREATE_DISP',
                dialogProps: {disp, plan, focusRow},
            })
        );
    };

    const edit = (disp: Dispensasjon) => {
        dispatch(
            showDialog({
                dialogType: 'EDIT_DISP',
                dialogProps: {plan, disp, focusRow},
            })
        );
    };

    const importFilesFromDisk = (event, dispensasjon: Dispensasjon) => {
        const files = Array.from(event.target.files) as File[];
        dispatch(
            showDialog({
                dialogType: 'IMPORT_DOCUMENTS',
                dialogProps: {
                    files,
                    dispensasjon: dispensasjon,
                    plan,
                    showRowDetails: focusRow,
                } as ImportDocumentDialogProps,
            })
        );

        // @ts-expect-error Trick input to fire onChange every time. Does not work on IE.
        const isIE = /*@cc_on!@*/ false || !!document.documentMode;
        if (!isIE) event.target.value = '';
    };

    const importFilesFromArchive = (dispensasjon: Dispensasjon) => {
        dispatch(
            showDialog({
                dialogType: 'IMPORT_DOCUMENTS_FROM_ARCHIVE',
                dialogProps: {
                    dispensasjon: dispensasjon,
                    plan,
                    showRowDetails: focusRow,
                } as ImportDocumentDialogProps,
            })
        );
    };

    const rearrangeDocuments = (dispensasjon: Dispensasjon) => {
        dispatch(
            showDialog({
                dialogType: 'REARRANGE_DOCUMENTS',
                dialogProps: {
                    plan,
                    dispensasjon: dispensasjon,
                    documents: orderBy(
                        dispensasjon.dokumenter,
                        ['rekkefolgeDispensasjon', 'dokumentType'],
                        ['asc', 'asc']
                    ),
                },
            })
        );
    };

    return {
        create,
        edit,
        importFilesFromArchive,
        importFilesFromDisk,
        rearrangeDocuments,
    };
};
