import React, {useEffect, useState} from 'react';
import {LinkNode} from '@lexical/link';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
    $createTextNode,
    $isRangeSelection,
    RangeSelection,
    TextNode,
} from 'lexical';
import {
    ToiBox,
    ToiButton,
    ToiCard,
    ToiCheckbox,
    ToiPopper,
    ToiTextField,
    ToiTypography,
} from '@norkart/toi-components';

export const LinkPopper = ({
    anchorEl,
    open,
    initialUrlTitle,
    initialUrl,
    onUrlPopperClose,
    editorSelection,
    onPopperTextChange,
}: {
    anchorEl: HTMLButtonElement | null;
    open: boolean;
    initialUrlTitle: string;
    initialUrl: string;
    onUrlPopperClose: () => void;
    editorSelection?: RangeSelection;
    onPopperTextChange: (url: string, text: string) => void;
}) => {
    const [url, setUrl] = useState('');
    const [urlTitle, setUrlTitle] = useState(initialUrlTitle);
    const [edit, setEdit] = useState<boolean>(false);
    const [openUrlAsTab, setOpenUrlAsTab] = useState<boolean>(true);
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        setUrlTitle(initialUrlTitle);
        setUrl(initialUrl);
        setEdit(initialUrl !== '');
    }, [initialUrlTitle, initialUrl, open]);

    function handleClose() {
        onUrlPopperClose();
        setUrlTitle('');
        setUrl('');
        setEdit(false);
        setOpenUrlAsTab(true);
    }

    function urlWithPrefix(url: string) {
        let prefixedUrl = url;
        if (!/^https:\/\//.test(prefixedUrl)) {
            prefixedUrl = 'https://' + prefixedUrl;
        }
        if (!/^https:\/\/www\./.test(prefixedUrl)) {
            prefixedUrl = prefixedUrl.replace(/^https:\/\//, 'https://www.');
        }
        return prefixedUrl;
    }

    const handleAddLink = () => {
        editor.update(() => {
            if (editorSelection) {
                const linknode = editorSelection
                    .getNodes()[0]
                    .getParent() as LinkNode;
                const txtnode = editorSelection.getNodes()[0] as TextNode;
                if (edit && linknode !== null) {
                    linknode.setTarget(url);
                    txtnode.setTextContent(urlTitle);
                } else {
                    const textNode = $createTextNode(urlTitle);
                    const linkAttributes = openUrlAsTab
                        ? {target: '_blank'}
                        : {};
                    const linkNode = new LinkNode(
                        urlWithPrefix(url),
                        linkAttributes
                    );
                    linkNode.append(textNode);
                    if ($isRangeSelection(editorSelection)) {
                        editorSelection.insertNodes([linkNode]);
                    }
                }
            }
        });
        handleClose();
    };

    return (
        <ToiPopper
            sx={{zIndex: '9999', border: 'solid', width: '250px'}}
            open={open}
            anchorEl={anchorEl}
        >
            <ToiCard
                sx={{display: 'flex', flexDirection: 'column', gap: 1, p: 2}}
            >
                <ToiTypography>Title:</ToiTypography>
                <ToiTextField
                    value={urlTitle}
                    onChange={(e) => {
                        const updatedText = e.target.value;
                        setUrlTitle(updatedText);
                        onPopperTextChange(url, updatedText);
                    }}
                />
                <ToiTypography>Target:</ToiTypography>
                <ToiTextField
                    value={url}
                    onChange={(e) => {
                        const updatedUrl = e.target.value;
                        setUrl(updatedUrl);
                        onPopperTextChange(updatedUrl, urlTitle);
                    }}
                />
                <ToiBox sx={{display: 'flex', flexDirection: 'column'}}>
                    <ToiBox sx={{display: 'flex', alignItems: 'center'}}>
                        <ToiCheckbox
                            checked={openUrlAsTab}
                            onChange={() => setOpenUrlAsTab(!openUrlAsTab)}
                        />
                        <ToiTypography>Open target in new tab</ToiTypography>
                    </ToiBox>
                    <ToiBox
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            py: 1,
                        }}
                    >
                        <ToiButton onClick={handleAddLink}>Add</ToiButton>
                        <ToiButton variant='outlined' onClick={handleClose}>
                            Close
                        </ToiButton>
                    </ToiBox>
                </ToiBox>
            </ToiCard>
        </ToiPopper>
    );
};
