import * as React from 'react';
import {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {
    ChatArea,
    ChatField,
    QuestionInput,
    ReferenceChatItemType,
    ResponseToUser,
} from '@norkart/nora-components';
import {ToiBox, ToiStack, ToiTypography} from '@norkart/toi-components';
import {ToiThemeProvider} from '@norkart/toi-theme';
import {toiColors} from '@norkart/toi-colors';
import {
    cacheNewChatHistoryItem,
    getCachedChatHistory,
} from '../../cache/chatHistory';
import {errorText} from '../../config/const';
import {postChat} from '../../services/apiPlanprat';
import {formatMultiplePlans} from '../../utils/formatArealplan';
import {Arealplan} from '../../hooks/arealplaner/types';
import usePlanerForEiendom from '../../hooks/plananalyse/usePlanerForEiendom';
import {useFilters} from '../../features/search/Search/filters';
import NoraTilbudLogo from '../../assets/images/NoraTilbudLogo.svg';
import useSearch from '../../hooks/search/useSearch';
import ResetButton from './ResetButton';
import {
    planprat,
    PlanpratEgenskaper,
    PlanpratMetadata,
    PlanpratResponseObject,
} from './types';
import {PlanFilter} from './PlanFilter/PlanFilter';

interface ChatProps {
    plans: Arealplan[];
    kundeId: string;
}

const Chat = ({plans, kundeId}: ChatProps) => {
    const [exampleQuestion, setExampleQuestion] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [chatHistory, setChatHistory] = useState(getCachedChatHistory());
    const search = useSearch();

    const [selectedMetadata, setSelectedMetadata] = useState<
        PlanpratMetadata[]
    >([]);

    const filters = useFilters();
    const knr = filters.get('knr');
    const gnr = Number(filters.get('gnr'));
    const bnr = Number(filters.get('bnr'));
    const fnr = Number(filters.get('fnr'));
    const snr = Number(filters.get('snr'));
    const planerForEiendom = usePlanerForEiendom({knr, gnr, bnr, fnr, snr});

    const formatChatHistory = () => {
        return chatHistory.map((item) => {
            if (item.reference) {
                return {
                    content: item.reference
                        .map((ref) => ref.contentText)
                        .join(' '),
                    type: item.type,
                };
            }

            return {
                content: item.content,
                type: item.type,
            };
        });
    };

    useEffect(() => {
        setSelectedMetadata(getMetadata());
    }, [planerForEiendom.data]);

    const getMetadata = (): PlanpratMetadata[] => {
        const selectedPlaner = search.results;

        if (!selectedPlaner) {
            return [];
        }

        return selectedPlaner
            .filter((plan) => plan.planStatusId === 3)
            .map((plan) => {
                return {
                    planId: plan.planId,
                    planNavn: plan.planNavn,
                    planType: plan.planType,
                    selected: true,
                    egenskaperFraDelareal: getEgenskaperFraDelareal(plan),
                } as PlanpratMetadata;
            });
    };

    const getEgenskaperFraDelareal = (
        arealPlan: Arealplan
    ): PlanpratEgenskaper[] => {
        const planData = planerForEiendom.data;

        if (!planData) {
            return [];
        }

        const planTyperToConsider = [
            planData.Kommunedelplaner,
            planData.Reguleringsplaner,
            planData.Kommuneplaner,
            planData.ReguleringsplanerOverBakken,
            planData.Bebyggelsesplaner,
            planData.BebyggelsesplanerOverBakken,
        ]
            .filter(
                (planer) => planer && planer !== undefined && planer.length > 0
            )
            .flat();

        const plan = planTyperToConsider.find(
            (plan) => plan?.PlanId === arealPlan.planId
        );

        if (!plan || !plan.Delareal) {
            return [];
        }

        return plan.Delareal.map((d) => {
            if (!d.Egenskaper) {
                return {egenskaper: [], selected: true};
            }

            return {
                egenskaper: d.Egenskaper.filter(
                    (e) => e.Navn !== 'Delareal'
                ).map((egenskap) => {
                    return {
                        navn: egenskap.Navn,
                        verdi: egenskap.Verdi,
                    };
                }),
                selected: true,
            } as PlanpratEgenskaper;
        });
    };

    const getPlanTypeFromSelectedMetadata = () => {
        const a = selectedMetadata
            .filter((metadata) => metadata.selected)
            .map((metadata) => metadata.planType);
        return a;
    };

    const updateHistory = (
        content?: string,
        ref?: ReferenceChatItemType[],
        type?: 'human' | 'ai' | 'system'
    ) => {
        const chatHistory: ResponseToUser = {
            content: content,
            reference: ref,
            type: type,
        };
        cacheNewChatHistoryItem({
            ...chatHistory,
            searchIndices: getPlanTypeFromSelectedMetadata(),
        });
        setChatHistory(getCachedChatHistory);
    };

    const resetHistory = () => {
        setChatHistory([]);
    };

    const generateAnswer = (
        content: PlanpratResponseObject[]
    ): ReferenceChatItemType[] | string => {
        if (content.length === 1 && !content[0].content_from) {
            return content[0].answer;
        }

        return content.map((obj) => {
            const corresponsingPlan = plans.find((plan) => {
                return plan.planId === obj.content_from?.plan_id;
            });

            const planName = corresponsingPlan
                ? `${corresponsingPlan.planNavn} - ${corresponsingPlan.planType}`
                : 'Planprat';

            const chunks = obj.content_from ? obj.content_from.chunks : [];

            return {
                title: planName,
                contentText: obj.answer,
                reference: {
                    chunks: chunks,
                },
            };
        });
    };

    const sendQuestion = async (message: string) => {
        setIsFetching(true);
        updateHistory(message, undefined, 'human');
        const plansFormatted = formatMultiplePlans(plans, selectedMetadata);
        const includeReferences = true;
        const chatHistoryFormatted = formatChatHistory();
        try {
            const res = await postChat(
                {
                    message,
                    chatHistoryFormatted,
                    plansFormatted,
                    includeReferences,
                },
                kundeId
            );
            const answer = generateAnswer(res.data.content);
            if (typeof answer === 'string') {
                updateHistory(answer, undefined, 'ai');
            } else {
                updateHistory(undefined, answer, 'ai');
            }
            setIsFetching(false);
        } catch (error: unknown) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                updateHistory(
                    'Å nei! En eller flere av planene for eiendommen er dessverre ikke tilgjengelig for Planprat. Det betyr at jeg ikke kan svare på spørsmålet ditt på denne eiendommen.',
                    undefined,
                    'ai'
                );
            } else {
                updateHistory(
                    'Det skjedde en feil. Vennligst prøv igjen',
                    undefined,
                    'ai'
                );
            }
        } finally {
            setIsFetching(false);
        }
    };

    return (
        <ToiThemeProvider>
            <ToiStack sx={{margin: 'auto'}}>
                <ChatArea>
                    <ToiBox
                        pt={4}
                        m='auto'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <ToiBox sx={{maxWidth: '60vh', width: '200px'}}>
                            <img
                                alt=''
                                src={NoraTilbudLogo}
                                style={{paddingRight: 15, width: '100%'}}
                            />
                        </ToiBox>
                        <ToiTypography
                            mt={2}
                            mb={2}
                            sx={{textAlign: 'center'}}
                            variant='h2'
                            color={toiColors.purple[100]}
                        >
                            Velkommen til PlanPrat!
                        </ToiTypography>
                        <ToiTypography
                            mb={5}
                            variant='h3'
                            sx={{textAlign: 'center'}}
                        >
                            Spør meg om planene for eiendommen
                        </ToiTypography>
                    </ToiBox>
                    <ChatField
                        botName='Planprat'
                        hasError={false}
                        selectedConversation={planprat}
                        conversationConfig={[]}
                        chatHistory={chatHistory}
                        isFetching={isFetching}
                        errorText={errorText}
                        setExampleQuestion={setExampleQuestion}
                    />
                </ChatArea>
                <ToiBox m={'auto'} width='80vw' maxWidth={'1100px'}>
                    <PlanFilter
                        selectedMetadata={selectedMetadata}
                        setSelectedMetadata={setSelectedMetadata}
                    />
                    <QuestionInput
                        disabled={false}
                        clearOnSend={true}
                        onSendChat={sendQuestion}
                        exampleQuestion={exampleQuestion}
                        setExampleQuestion={setExampleQuestion}
                        isLoading={false}
                        conversationType={planprat}
                        onAbortMutation={function (): void {
                            throw new Error('Function not implemented.');
                        }}
                    />
                    <ResetButton resetChatHistory={resetHistory} />
                </ToiBox>
            </ToiStack>
        </ToiThemeProvider>
    );
};

export default Chat;
