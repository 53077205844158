export type KodeVerdi = {
    id: string;
    beskrivelse: string;
};

export enum KodelisteType {
    Journalposttyper = 'journalposttyper',
    Journalstatuser = 'journalstatuser',
    Tilgangsrestriksjoner = 'tilgangsrestriksjoner',
}
export type KodelisteTilgang = {
    id: number;
    kodelisteType: KodelisteType;
    tilgangId: number;
    kodeverdi: string;
    kodebeskrivelse: string;
};
