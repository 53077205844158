import {useMutation, useQueryClient} from 'react-query';
import {createDispensasjon} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Dispensasjon, DispensasjonFormData} from './types';

const addDispensasjon = async (
    dispensasjon: DispensasjonFormData
): Promise<Dispensasjon> => {
    const {data} = await createDispensasjon(dispensasjon);
    return data;
};

export default function useCreateDispensasjon() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();

    return useMutation(addDispensasjon, {
        onError: notify,
        onSuccess: (dispensasjon) => {
            queryClient.invalidateQueries([
                'dispensasjoner',
                {kundeId, arealplanId: dispensasjon.arealplanId},
            ]);
        },
    });
}
