import {fetchDokument} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';

const getDokument = async (DokumentId: number) => {
    const {data} = await fetchDokument(DokumentId);
    return data;
};

export default function useDokuments() {
    const {notify} = useErrorHandler();

    const openDokument = async (
        dokumentId: number,
        target: 'tab' | 'replace' = 'tab'
    ) => {
        try {
            if (target == 'tab') {
                const windowRef = window.open();
                const res = await getDokument(dokumentId);
                if (windowRef) windowRef.location = res.direkteUrl;
            }
            if (target == 'replace') {
                const res = await getDokument(dokumentId);
                window.location.replace(res.direkteUrl);
            }
        } catch (error) {
            notify(error);
        }
    };

    const getDokumentUrl = async (
        dokumentId: number
    ): Promise<string | undefined> => {
        try {
            const res = await getDokument(dokumentId);
            return res.direkteUrl;
        } catch (error) {
            notify(error);
        }
    };

    return {
        openDokument,
        getDokumentUrl,
    };
}
