import * as React from 'react';
import './bodystyle.css';
import {Routes, Route} from 'react-router-dom';
import {useSsoSilent} from '@norkart/nk-auth';
import {ApplicationInsightsProvider} from '@norkart/core-applicationinsights';
import OpenDocument from './features/dokumenter/OpenDocument';
import AppRoutes from './routes/AppRoutes';
import {setTitle} from './utils/setTitle';
import arealplanerConfig from './config';

export function Application() {
    setTitle();
    const silentLogin = useSsoSilent(true);

    return (
        <ApplicationInsightsProvider
            connectionString={
                arealplanerConfig.applicationInsightsConnectionString
            }
        >
            <Routes>
                <Route
                    path='/:customerId/dokumenter/:dokumentId/:dokumentNavn'
                    element={<OpenDocument />}
                />
                <Route path='/*' element={<AppRoutes />} />
            </Routes>
        </ApplicationInsightsProvider>
    );
}

export default Application;
