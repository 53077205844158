import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import React, {useMemo} from 'react';
import {Feature, MultiPolygon, Polygon} from 'geojson';
import {AppSearchIcon} from '@norkart/toi-icons';
import {getMapState, getSearchState} from '../../../../store';
import {addFitToBounds} from '../../../../store/map/actions';
import {createFitBoundsForFeature} from '../../helpers/layerHelpers';
import {hoverOverWhiteColor} from '../../../../theme';

type Props = {
    type: 'property' | 'plan';
    zoomTo?: Feature<MultiPolygon | Polygon>;
    style?: any;
};

const ZoomButton = ({type, zoomTo, style}: Props) => {
    const mapState = useSelector(getMapState);
    const searchState = useSelector(getSearchState);
    const dispatch = useDispatch();

    const planAreaFitBounds = useMemo(
        () =>
            mapState.borderLayers?.combined &&
            createFitBoundsForFeature(
                zoomTo ||
                    (mapState.borderLayers?.combined.source as any).data
                        .geometry
            ),
        [mapState.borderLayers?.combined, zoomTo]
    );

    const propertyFitBounds = useMemo(
        () =>
            searchState.searchedPropertyGeom &&
            createFitBoundsForFeature(searchState.searchedPropertyGeom),
        [searchState.searchedPropertyGeom]
    );

    const onZoom = () => {
        if (type === 'property') {
            dispatch(addFitToBounds(propertyFitBounds));
        } else {
            dispatch(addFitToBounds(planAreaFitBounds));
        }
    };

    return (
        <Box
            sx={(theme) => ({
                button: {
                    cursor: 'pointer',
                    border: '1px solid #0000003d',
                    backgroundColor: 'white',
                    width: '70px',
                    height: '70px',
                    marginLeft: ' 5px',
                    marginRight: ' 5px',
                    '& p': {
                        marginTop: 2,
                        marginBottom: 2,
                    },
                    '&:focus': {
                        outline: 'none',
                        border: '2px solid ' + theme.palette.secondary.main,
                    },
                    '&:hover': {
                        backgroundColor: hoverOverWhiteColor,
                    },
                },
            })}
        >
            <button onClick={onZoom} style={style}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <AppSearchIcon blackWhite />
                    <p>{`Zoom til ${
                        type === 'property' ? 'eiendom' : 'plan'
                    }`}</p>
                </div>
            </button>
        </Box>
    );
};

export default ZoomButton;
