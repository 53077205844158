import {useQuery} from 'react-query';
import {fetchArkivKodelisteTilganger} from '../../services/api';
import {KodelisteTilgang, KodelisteType} from './types';

const getKodeliste = async (arkivId: number, kodelisteType: KodelisteType) => {
    const {data} = await fetchArkivKodelisteTilganger(arkivId, kodelisteType);
    return data as KodelisteTilgang[];
};

export function useArkivKodelisteTilganger(
    arkivId: number,
    kodelisteType: KodelisteType
) {
    const query = useQuery<KodelisteTilgang[]>(
        ['arkivkodelistetilganger', arkivId, kodelisteType],
        () => getKodeliste(arkivId, kodelisteType),
        {refetchOnWindowFocus: false}
    );
    return {...query, data: query?.data || ([] as KodelisteTilgang[])};
}
