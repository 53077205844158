import React, {lazy} from 'react';
import {ApplicationInsightsLoaderProps} from './ApplicationInsightsLoader';

const ApplicationInsightsLoader = lazy(
    () => import('./ApplicationInsightsLoader')
);

export const ApplicationInsightsProvider = (
    props: ApplicationInsightsLoaderProps
) => {
    return props.connectionString ? (
        <ApplicationInsightsLoader {...props} />
    ) : (
        props.children
    );
};
