import {
    Box,
    ClickAwayListener,
    Grid,
    IconButton,
    Popper,
    Tab,
    Tabs,
    TextFieldProps,
} from '@mui/material';
import * as React from 'react';
import {format, parse, isMatch} from 'date-fns';
import {nb} from 'date-fns/locale';
import ClearIcon from '@mui/icons-material/Clear';
import {AppCalendarIcon} from '@norkart/toi-icons';
import {ToiLikeStyledTextField} from '../../TextField/ToiLikeStyledTextField';
import {TabPanel} from './TabPanel';
import {DateRangePickerCalendar} from './DateRangePickerCalendar';
import {DateRangePickerFlexDates} from './DateRangePickerFlexDates';

export type StartOrEndDate = {
    start?: Date;
    end?: Date;
};

type DatePickerMuiProps = {
    onChange: (date: StartOrEndDate | undefined) => void;
    label?: string;
    startDate?: Date;
    endDate?: Date;
    textFieldProps?: TextFieldProps;
    visibleIntervals?: 'months' | 'years' | 'all';
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const isValidDate = (value: string) => {
    let parsedDate;
    let validCheck = false;

    try {
        const cleanValue = value.replace(/[\s-]/g, '');
        if (cleanValue.length === 10) {
            const doesStringMatchPattern = isMatch(cleanValue, 'dd.MM.yyyy');
            if (doesStringMatchPattern) {
                parsedDate = parse(cleanValue, 'P', new Date(), {locale: nb});
                validCheck = dateValidator(cleanValue);
            }
        }

        return {
            valid: validCheck,
            date: (parsedDate ? parsedDate : undefined) as Date | undefined,
        };
    } catch {
        return {
            valid: validCheck ? true : false,
            date: undefined,
        };
    }
};

const formatStringFromDate = (input: Date) => {
    let formattedString;
    try {
        formattedString = format(input, 'dd.MM.yyyy');
    } catch {
        formattedString = '';
    }
    return formattedString as string;
};

const setCursorPosition = (ref: HTMLInputElement, position: number) => {
    ref.selectionStart = position;
    ref.selectionEnd = position;
};

const dateValidator = (input?: string) => {
    const DATE_REGEX = new RegExp(
        '^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.](19|20)\\d{2}$'
    );
    if (!input) return false;
    const validationResult = DATE_REGEX.test(input);
    return validationResult;
};

function DateRangePickerMui({
    onChange,
    label,
    startDate,
    endDate,
    textFieldProps,
    visibleIntervals,
    ...props
}: DatePickerMuiProps) {
    const [anchorEl, setAnchorEl] = React.useState<
        HTMLInputElement | undefined
    >();
    const textFieldRef = React.useRef<HTMLInputElement>();
    const [textFieldCursor, setTextFieldCursor] = React.useState<
        number | undefined
    >();
    const [calendarOpen, setCalendarOpen] = React.useState<boolean>(false);
    const [calendarValues, setCalendarValues] =
        React.useState<StartOrEndDate>();
    const [dateRangeValue, setDateRangeValue] = React.useState<string>('');
    const [tabPanelValue, setTabPanelValue] = React.useState(0);
    const [dateError, setDateError] = React.useState<
        {start: boolean; end: boolean} | undefined
    >();

    function handleOpenCalendar(e) {
        setAnchorEl(textFieldRef.current);
        setCalendarOpen(!calendarOpen);
    }

    //Prøver å holde styr på plasseringen til cursor
    React.useEffect(() => {
        if (textFieldCursor) {
            if (textFieldRef.current) {
                setCursorPosition(textFieldRef.current, textFieldCursor);
            }
            setTextFieldCursor(undefined);
        }
    }, [textFieldCursor]);

    //Oppdaterer kalenderverdier/valgte datoer bassert på input - validerer at det er en dato før den prøver å sette ny dato
    React.useMemo(() => {
        const dateEditTimeout = setTimeout(() => {
            const e = isValidDate(dateRangeValue.slice(13, 23));
            const s = isValidDate(dateRangeValue.slice(0, 10));

            if (dateRangeValue.length >= 23)
                setDateError({start: !s.valid, end: !e.valid});
            if (e.date !== endDate || s.date !== startDate) {
                //setCalendarValues({ start: s.date, end: e.date })
                onChange({start: s.date, end: e.date});
            }
        }, 500);

        return () => clearTimeout(dateEditTimeout);
    }, [dateRangeValue]);

    //Sjekker hva som er valgt av datoer og setter det i input-feltet.
    React.useMemo(() => {
        if (
            startDate &&
            formatStringFromDate(startDate) !== dateRangeValue.slice(0, 10)
        ) {
            if (
                (dateRangeValue.length >= 10 || dateRangeValue.length === 0) &&
                !endDate
            ) {
                setDateRangeValue(formatStringFromDate(startDate) + ' - ');
            } else if (calendarValues?.end && dateRangeValue.length >= 10) {
                const newDateRange =
                    formatStringFromDate(startDate) + ' - ' + dateRangeValue;
                setDateRangeValue(newDateRange);
            }
        }
        if (
            endDate &&
            formatStringFromDate(endDate) !== dateRangeValue.slice(13, 24)
        ) {
            if (
                startDate &&
                dateRangeValue.length >= 13 &&
                isValidDate(dateRangeValue.slice(13, 23)).date !== endDate
            ) {
                const newDateRange = dateRangeValue;
                const rangeNewEnd = newDateRange.replace(
                    dateRangeValue.slice(10, dateRangeValue.length),
                    ' - ' + formatStringFromDate(endDate)
                );
                setDateRangeValue(rangeNewEnd);
            }
        }

        if (startDate && endDate) {
            setDateRangeValue(
                `${formatStringFromDate(startDate)} - ${formatStringFromDate(
                    endDate
                )}`
            );
        }
        if (!startDate && !endDate) setDateRangeValue('');
    }, [startDate, endDate]);

    //Masker input
    const dateRangeInputValidator = (e: any | undefined) => {
        if (e?.target?.value) {
            const cleanValue = e.target.value.replace(
                /[^0-9]*$/g,
                ''
            ) as string;
            const textValue = cleanValue;
            const separator = '.';
            const rangeSeparator = ' - ';
            const positions = [2, 5, 10, 15, 18];

            const lastKey = textValue[textValue.length - 1];

            if (
                lastKey === separator ||
                lastKey === ' - ' ||
                lastKey === ' ' ||
                lastKey === '-'
            ) {
                return;
            }
            if (textValue.length >= 24) return;

            let newValue = textValue;
            for (const pos of positions) {
                if (newValue.length === pos) {
                    if (
                        pos === 10 &&
                        isValidDate(newValue.slice(0, 10)).valid
                    ) {
                        newValue = newValue.concat(rangeSeparator);
                    } else if (newValue.length > 13 && newValue.includes('-')) {
                        newValue = newValue.concat(separator);
                    } else if (newValue.length < 10)
                        newValue = newValue.concat(separator);
                    else {
                        setDateError({
                            start: !isValidDate(newValue.slice(0, 10)).valid,
                            end: !isValidDate(newValue.slice(13, 23)).valid,
                        });
                    }
                }
            }
            textFieldRef.current?.selectionStart &&
                setCursorPosition(
                    textFieldRef.current,
                    textFieldRef.current.selectionStart
                );
            setDateRangeValue(newValue);
        }
    };

    //Håndterer sletting av tall, funker så-som-så
    function handleKeyDown(event: any) {
        const selectionStart = event.target.selectionStart;
        const selectionEnd = event.target.selectionEnd;
        const value = event.target?.value as string;

        if (event.key === 'Backspace' && selectionStart === selectionEnd) {
            const lastChar = value[value.length - 1];
            if (lastChar && lastChar !== ' ' && lastChar !== '-') {
                setDateRangeValue(
                    value.substring(0, selectionStart - 1) +
                        value.substring(selectionStart, value.length)
                );
                setTextFieldCursor(selectionStart);
            } else if (lastChar === ' ' || lastChar === '-') {
                setDateRangeValue(value.slice(0, -3));
                setTextFieldCursor(selectionStart);
            }
        }

        if (
            (event.key === 'Backspace' || event.key === 'Delete') &&
            selectionStart !== selectionEnd
        ) {
            const selectionStart = event.target.selectionStart;
            const selectionEnd = event.target.selectionEnd;

            const textToRemove = value.slice(selectionStart, selectionEnd);
            setDateRangeValue(value.replace(textToRemove, ''));
            setTextFieldCursor(selectionStart);
        }
    }

    //Funksjon for å cleare dato/tekst
    const clearDate = () => {
        //setCalendarValues({ start: undefined, end: undefined })
        setDateRangeValue('');
        setDateError(undefined);
        onChange({});
    };

    return (
        <ClickAwayListener onClickAway={(e) => setCalendarOpen(false)}>
            <div>
                <ToiLikeStyledTextField
                    {...textFieldProps}
                    label={label}
                    size='small'
                    color='secondary'
                    fullWidth={true}
                    onKeyDown={handleKeyDown}
                    placeholder='dd.mm.yyyy - dd.mm.yyyy'
                    autoComplete='off'
                    onChange={dateRangeInputValidator}
                    value={dateRangeValue}
                    inputRef={textFieldRef}
                    InputLabelProps={{
                        shrink: false,
                    }}
                    helperText={
                        (dateError?.start &&
                            !dateError.end &&
                            'Feil med startdato, format: "dd.MM.yyyy"') ||
                        (dateError?.end &&
                            !dateError?.start &&
                            'Feil med sluttdato, format: "dd.MM.yyyy"') ||
                        (dateError?.start &&
                            dateError?.end &&
                            'Feil med begge datoer, format: dd.MM.yyyy - dd.MM.yyyy')
                    }
                    error={
                        dateError ? dateError?.start || dateError?.end : false
                    }
                    InputProps={{
                        inputProps: {onClick: () => setCalendarOpen(false)},
                        endAdornment: (
                            <>
                                {dateRangeValue && (
                                    <IconButton
                                        className='deleteIcon'
                                        title='Clear'
                                        aria-label='Clear'
                                        size='small'
                                        onClick={() => clearDate()}
                                    >
                                        <ClearIcon
                                            color='primary'
                                            fontSize='inherit'
                                        />
                                    </IconButton>
                                )}
                                <IconButton
                                    className='openPicker'
                                    title='Open calendar'
                                    aria-label='Open calendar'
                                    size='small'
                                    onClick={handleOpenCalendar}
                                >
                                    <AppCalendarIcon />
                                </IconButton>
                            </>
                        ),
                    }}
                />
                <Popper
                    anchorEl={anchorEl}
                    placement='bottom-start'
                    open={calendarOpen}
                    sx={{backgroundColor: '#f9f9f9', zIndex: 1}}
                >
                    <Grid container={true}>
                        <Box
                            sx={(theme) => ({
                                border: `1px solid ${theme.palette.grey[300]}`,
                                boxShadow: 3,
                                marginTop: 1,
                                width: 666,
                                height: 420,
                                backgroundColor: '#f9f9f9',
                            })}
                        >
                            <Tabs
                                centered={true}
                                sx={{
                                    backgroundColor: '#f9f9f9',
                                }}
                                value={tabPanelValue}
                                indicatorColor='secondary'
                                textColor='primary'
                                onChange={(e, value) => setTabPanelValue(value)}
                            >
                                <Tab label='Velg datoer' {...a11yProps(0)} />
                                <Tab label='Datointervall' {...a11yProps(1)} />
                            </Tabs>
                            <Grid item={true}>
                                <TabPanel value={tabPanelValue} index={0}>
                                    <DateRangePickerCalendar
                                        calendarValues={{
                                            start: startDate || new Date(),
                                            end: endDate || new Date(),
                                        }}
                                        onChange={(e) =>
                                            onChange({
                                                start: e.start,
                                                end: e.end,
                                            })
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value={tabPanelValue} index={1}>
                                    <DateRangePickerFlexDates
                                        intervalSelected={(d) =>
                                            onChange({
                                                start: d.start,
                                                end: d.end,
                                            })
                                        }
                                        show={visibleIntervals}
                                    />
                                </TabPanel>
                            </Grid>
                        </Box>
                    </Grid>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

export default DateRangePickerMui;
