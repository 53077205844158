import {isEmpty} from 'lodash-cjs';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {ToiBox, ToiButton, ToiLogo} from '@norkart/toi-components';
import {
    municipalitiesWithHeradEnding,
    northSamiMunicipalities,
    southSamiMunicipalities,
} from '../../../config/const';
import useKommuneInfo from '../../../hooks/kommuneinfo/useKommuneInfo';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import useIsDemoVersion from '../../../hooks/useIsDemoVersion';

function TopbarKommuneHeader() {
    const kommune = useKommuneInfo();
    const navigate = useNavigate();
    const kundeId = useSelectedKundeId();
    const isDemoVersion = useIsDemoVersion();

    const shouldDisplayNK =
        isDemoVersion || Object.keys(kommune.data).length === 0;

    const kommuneTitle = useMemo(
        () =>
            municipalitiesWithHeradEnding.includes(
                kommune?.data?.KommuneName?.nor
            )
                ? kommune?.data?.KommuneName?.nor + ' herad'
                : kommune?.data?.KommuneName?.nor + ' kommune',
        [municipalitiesWithHeradEnding, kommune?.data?.KommuneName?.nor]
    );

    const samiMunicipalityTitle = useMemo(() => {
        if (southSamiMunicipalities.includes(kommune?.data?.KommuneName?.nor)) {
            return kommune?.data?.KommuneName?.sma + ' tjïelte';
        }

        if (northSamiMunicipalities.includes(kommune?.data?.KommuneName?.nor)) {
            return kommune?.data?.KommuneName?.sma + ' suohkan';
        }
        return null;
    }, [
        southSamiMunicipalities,
        northSamiMunicipalities,
        kommune?.data?.KommuneName?.nor,
        kommune?.data?.KommuneName?.sma,
    ]);

    if (isEmpty(kommune.data)) return null;

    const handleMunicipalityClick = () => {
        navigate(`${kundeId}/arealplaner/search`);
    };

    return (
        <ToiButton
            variant='text'
            color='secondary'
            sx={{
                fontWeight: 'light',
                fontSize: '16px',
                gap: '10px',
                ':hover': {backgroundColor: 'white'},
            }}
            onClick={handleMunicipalityClick}
        >
            {shouldDisplayNK ? (
                <>
                    <ToiLogo variant='icon' width={23} sx={{pt: 1}} />
                    {'Norkart AS'}
                </>
            ) : (
                <>
                    <img
                        src={kommune.data.KommuneCrest}
                        alt='Kommunelogo'
                        style={{height: '28px'}}
                    />
                    {!samiMunicipalityTitle && kommuneTitle}
                </>
            )}
            {samiMunicipalityTitle && (
                <ToiBox
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <ToiBox sx={{lineHeight: 1, alignSelf: 'flex-start'}}>
                        {kommuneTitle}
                    </ToiBox>
                    <ToiBox sx={{lineHeight: 1}}>
                        {samiMunicipalityTitle}
                    </ToiBox>
                </ToiBox>
            )}
        </ToiButton>
    );
}

export default TopbarKommuneHeader;
